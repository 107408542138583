/**
 * Created by 野孩子 Robin on 15/11/4.
 * email: robin.yao@zoompoint.net 2015
 */

var GwkMapController = {
    /*
     initialization
     */
    init: {
        handle: function (_callback,args) {

            GwkMapController.helper.loadScripts();

            if(GwkMapController.scriptLoaded != true){
                setTimeout(function(){
                    GwkMapController.init.handle(_callback,args);
                },500);
                return;
            }

            if(!GwkMapController.mapInited){
                //GwkMapController.init.initMapKey();
                GwkMapController.init.initCoords();
                GwkMapController.init.initMap();
                GwkMapController.init.initLayers();
                GwkMapController.init.initEvents();

                //GwkMapController.geoLocationProvider = new Microsoft.Maps.GeoLocationProvider(GwkMapController.map);
                GwkMapController.default_cusor = GwkMapController.map.getContainer().style.cursor;
                //GwkMapController.helper.mapFitWindow();
                GwkMapController.mapInited = true;
                GwkMapController.helper.setUserLocationPin(GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng, "您大概的位置");
                //GwkMapController.init.initGoogle();
                console.log("User Agent="+GwkMapController.userAgent);
                GwkMapController.mapInited = true; //!!!!
            }

            console.log("-------init(): handel callback");
            if(_callback != null && undefined != _callback){
                _callback.apply(this,args);
            }

        },


        initGoogle: function() {
            try{
            GwkMapController.googleMap = new google.maps.Map(document.getElementById('map-google'),{});
            GwkMapController.placeService = new google.maps.places.PlacesService(GwkMapController.googleMap);
            console.log("Google Map init ok.");
            } catch(e) {
                console.log("Google Map init failed!");
                console.log(e);
            }

        },

        initMapKey: function(){
            L.mapbox.accessToken = GwkMapController.mapbox_api_key;
        },

        initMap: function () {
            //GwkMapController.helper.mapFitWindow();

            var window_width= $(window).width();
            //对于小屏幕设备, 设置一个较小的zoom level
            var minZoom = 1;
            if(window_width > 736){
                minZoom = 2;
            }
            GwkMapController.map = L.mapbox.map('map', null, {zoomControl: false,worldCopyJump: true,minZoom:minZoom, maxZoom:17, timeout:5000,enableHighAccuracy:true}).fitWorld();
            //限定地图拖动范围,避免上下端出现空白
            var southWest = L.latLng(-90, -361),
                northEast = L.latLng(90, 361),
                bounds = L.latLngBounds(southWest, northEast);
            GwkMapController.map.setMaxBounds(bounds);
            //显示比例尺
            L.control.scale({imperial:false}).addTo(GwkMapController.map);

            GwkMapController.geoLocationProvider = navigator.geolocation;
            GwkMapController.map.fitWorld();

        },

        initCoords: function () {
            //var _init_local_lat = $.cookie('latitude');
            //var _init_local_lon = $.cookie('longitude');
            //if (undefined != _init_local_lat && undefined != _init_local_lon) {
            //    GwkMapController.init_user_location = L.latLng(_init_local_lat, _init_local_lon);
            //}
            GwkMapController.init_user_location = L.latLng(GwkMapController.init_user_lat, GwkMapController.init_user_lng);

        },

        initLayers: function () {
            if (null == GwkMapController.map) {
                GwkMapController.init.initMap();
            }
            GwkMapController.GwkUserLayer = L.mapbox.featureLayer().addTo(GwkMapController.map);
            GwkMapController.GwkUtilLayer = L.mapbox.featureLayer().addTo(GwkMapController.map);
            GwkMapController.GwkBaseMapGroup = L.mapbox.featureLayer().addTo(GwkMapController.map);
            //GwkMapController.GwkStreetLayer = L.mapbox.tileLayer('mapbox.streets', {
            //        zIndex:1001,
            //        detectRetina:true,
            //        zoom:GwkMapController.zoom}
            //).addTo(GwkMapController.GwkBaseMapGroup);
            L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v9',{
                        zIndex:1001,
                        detectRetina:true}).addTo(GwkMapController.GwkBaseMapGroup);
            //GwkMapController.GwkSatelliteLayer = L.mapbox.tileLayer('mapbox.streets-satellite', {zIndex:-10,detectRetina:true}).addTo(GwkMapController.map);
            GwkMapController.GwkPOIClusterGroup = L.featureGroup().addTo(GwkMapController.map);
            GwkMapController.GwkActivityClusterGroup = L.featureGroup().addTo(GwkMapController.map);

        },

        initEvents: function () {
            if (null == GwkMapController.map) {
                GwkMapController.helper.attempt(GwkMapController.initMap);
            }

            GwkMapController.helper.attempt(function () {
                GwkMapController.map.on('click', GwkMapController.eventHandler.mapClick);
            });
            GwkMapController.helper.attempt(function () {
                //GwkMapController.map.on('zoomend', GwkMapController.eventHandler.mapZoomend);
                GwkMapController.map.on('resize', GwkMapController.eventHandler.mapResize);
                GwkMapController.map.on('dragend', GwkMapController.eventHandler.mapDragend);
                //GwkMapController.map.on('viewreset', GwkMapController.eventHandler.mapViewChange);
                //GwkMapController.map.on('moveend', GwkMapController.eventHandler.mapMoveend);
            });

            if(!Modernizr.touch) {
                $('[data-toggle="tooltip"]').tooltip();
            }
            //右下角地图控制工具
            $('[data-toggle="popover"]').popover({
                html: true
            });

            $('body').on('click', function (e) {

                $('[id=menu-btn-ctn]').popover("hide");
                $('[data-toggle="popover"]').each(function () {
                    //the 'is' for buttons that trigger popups
                    //the 'has' for icons within a button that triggers a popup
                    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                        $(this).popover('hide');
                    }
                });

            });
            //GwkMapController.helper.attempt(function () {
            //    GwkMapController.map.on('mousemove', GwkMapController.eventHandler.mapMouseMove);
            //});

            //$(window).resize(function () {
            //    GwkMapController.helper.attempt(GwkMapController.helper.mapFitWindow);
            //});

            //$('#user-search-around').on('click', function(){$('#panel-content-dest').collapse('show');});

        }

    },

    eventHandler: {
        mapMouseMove: function (e) {
            //Do Nothing.
        },

        mapClick: function (e) {
            // $('.panel-to-toggle').slideUp();
        },

        mapZoomend: function(){
            console.log('map zoomend !!! ');
            GwkMapController.init_user_location = GwkMapController.map.getCenter();
        },
        mapDragend: function(){
            console.log('map dragend !!! ');
            GwkMapController.init_user_location = GwkMapController.map.getCenter();
        },
        mapResize: function(){
            console.log('map resized !!! ');
            GwkMapController.init_user_location = GwkMapController.map.getCenter();
        },
        mapMoveend: function() {
            console.log('map moveend !!! zoom = ' + GwkMapController.map.getZoom());
            GwkMapController.init_user_location = GwkMapController.map.getCenter();
            //处理目前已经选中的运动类型,根据地图bounds重新渲染POI图标
            //$("li.sport_type").each(function(){
            //    var flag = $(this).find('input.togglePoiState').val();
            //    var sport_type = $(this).find('input.sport_type_code').val();
            //    //console.log(sport_type + " = " + flag);
            //    if(flag==1){
            //        $(this).find('input.togglePoiState').val(0);
            //        GwkMapController.toggle.togglePOIData(sport_type);
            //    }
            //});
        },

        mapViewChange: function () {
            var _nw = GwkMapController.map.getBounds().getNorthWest();
            var _se = GwkMapController.map.getBounds().getSouthEast();
            console.log('map view changed !!!');

        },

    },

    helper: {

        //地图尺寸随窗口大小变化
        mapFitWindow: function () {

            var map_container = document.getElementById("map");
            map_container.style.height = ($(window).height() - 250)+ "px";
            map_container.style.width = $(window).width() + "px";
        },
        //获取用户位置(force_relocate=true, 表明在H5定位失败后,强制重新根据IP定位而不是使用之前的定位结果缓存)
        captureUserCoords: function (force_relocate, set_pin) {

            //GwkMapController.helper.loadScripts();
            //
            //if(GwkMapController.scriptLoaded != true){
            //    setTimeout(function(){
            //        GwkMapController.helper.captureUserCoords(force_relocate, set_pin);
            //    },500);
            //    return;
            //}
            //
            //console.log('ok load scripts is finish now!');

            if(force_relocate){
                GwkMapController.userAddress = null;
                GwkMapController.init_user_location = null;
                GwkMapController.init_user_lat = 0;
                GwkMapController.init_user_lng = 0;
            }

            if(!navigator.geolocation){
                GwkMapController.helper.showMsg('您的浏览器不支持位置服务。','error');
            } else {
                GwkMapController.geoLocationProvider = navigator.geolocation;
                try{
                    console.log("开始获取用户位置....");
                    //GwkMapController.map.locate({enableHighAccuracy: true, timeout: 5000});
                    //GwkMapController.map.on("locationfound",GwkMapController.helper.successCallback);
                    //GwkMapController.map.on("locationerror",GwkMapController.helper.errorCallback);
                    var success_callback = GwkMapController.helper.successCallback;
                    var error_callback   = GwkMapController.helper.errorCallback;
                    if(!set_pin){
                        success_callback = GwkMapController.helper.successCallback_no_pin;
                        error_callback   = GwkMapController.helper.errorCallback_no_pin;
                    }
                    GwkMapController.geoLocationProvider.getCurrentPosition(success_callback, error_callback,
                        {
                            // 指示浏览器获取高精度的位置，默认为false
                            enableHighAccuracy: true,
                            // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
                            timeout: 5000,
                            // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
                            maximumAge: 3000
                        });
                    console.log("获取用户位置结束....");
                } catch(e){
                    console.log("获取用户位置出现错误:" + e);

                }
            }

        },

        //定位成功后的处理
        successCallback: function (_pos) {
            console.log("locate user position success ..."+_set_pin);
            //var lat = _pos.latlng.lat;
            //var lng = _pos.latlng.lng;
            var lat = _pos.coords.latitude;
            var lng = _pos.coords.longitude;
            //GwkMapController.init_user_location = L.latLng(lat, lng);
            //$.cookie('latitude', lat, {expires: 7, path: '/'});
            //$.cookie('longitude', lng, {expires: 7, path: '/'});


            //设置地图中心点为用户当前位置
            //var currentZoom = GwkMapController.map.getZoom();
            GwkMapController.map.setView([lat, lng], 12);

            //var _html = "<p>您现在的位置</p><a href='#'>探索其他地方</a><br><a href='#'>高级搜索</a>";
            GwkMapController.helper.queryUserAddress(lat,lng);
            var _html = "您现在的位置";
            GwkMapController.helper.setUserLocationPin(lat,lng,_html);

        }, //end successCallback

        errorCallback: function (error) {
            console.log("locate user position error: "+error.code);
            switch(error.code) {
                case error.TIMEOUT:
                    console.log("A timeout occured! Please try again!");
                    break;
                case error.POSITION_UNAVAILABLE:
                    console.log('We can\'t detect your location. Sorry!');
                    break;
                case error.PERMISSION_DENIED:
                    console.log('Please allow geolocation access for this to work.');
                    break;
                case error.UNKNOWN_ERROR:
                    console.log('An unknown error occured!');
                    break;
                default:
                    console.log('An unknown??? error occured!');
                    break;
            }
            //如果不能通过H5 api定位,则采用后台IP定位结果确定用户位置
            if(GwkMapController.init_user_location != null && GwkMapController.init_user_location != undefined){
                var _html = "您大概的位置:<br><strong>"+GwkMapController.userAddress+"</strong>";
                GwkMapController.map.setView([GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng], 12);
                GwkMapController.helper.setUserLocationPin(GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng,_html);
                GwkMapController.helper.queryUserAddress(GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng);
                return;
            } else {
                var _js_url = "/getUserLocation";
                $.ajax({
                    url: _js_url,
                    dataType: "json",
                    cache:true,
                    async:false,
                    success: function(data){
                        var lat = data.lat;
                        var lng = data.lng;
                        var address = data.address;
                        GwkMapController.userAddress = address;
                        GwkMapController.init_user_location = L.latLng(lat, lng);
                        var _html = "您大概的位置:<br><strong>"+GwkMapController.userAddress+"</strong>";
                        GwkMapController.helper.setUserLocationPin(GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng,_html);
                        GwkMapController.helper.queryUserAddress(GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng);
                        setTimeout(function () {
                            GwkMapController.map.setView([lat, lng], 10);
                        }, 10);
                        console.log("根据IP获取用户地址成功:" + GwkMapController.helper.obj2string(data));
                    },
                    error: function(e){
                        console.log("根据IP获取用户地址失败!" + e);
                    }
                });
            }

            //如果通过传统方式(navigator.geolocation)不能定位用户,则通过新浪接口获取用户大概位置
            //$.getScript('http://int.dpool.sina.com.cn/iplookup/iplookup.php?format=js',function(){
            //    var country = remote_ip_info.country;
            //    var province = remote_ip_info.province;
            //    var city = remote_ip_info.city;
            //    console.log("无法精确定位,尝试使用IP获取用户大概位置:" + city + ", " + province + ", " + country);
            //    if(country == "" && province=="" && city == ""){
            //        //如果仍然无法获取用户大概位置,则显示错误信息并设置默认位置
            //        GwkMapController.init_user_location = GwkMapController.default_location;
            //
            //        console.log("根据用户IP无法获取用户位置...");
            //        //GwkMapController.helper.showMsg('无法获取您当前的位置。','error');
            //
            //        //将地图定位到世界中心。。。
            //        GwkMapController.helper.setUserLocationPin(0,0,"无法确定您的位置...")
            //        GwkMapController.map.setView([0,0],2);
            //    } else {
            //        //根据获取的大概位置,通过MAP BOX API查询位置并设置中心点
            //        var geocoder = L.mapbox.geocoder('mapbox.places');
            //        geocoder.query(city+","+province+","+country, GwkMapController.helper.showImpreciseLocation);
            //    }
            //});
        },

        successCallback_no_pin: function (_pos) {

            var lat = _pos.coords.latitude;
            var lng = _pos.coords.longitude;

            GwkMapController.helper.queryUserAddress(lat,lng);

        }, //end successCallback
        errorCallback_no_pin: function (error) {
            console.log("locate user position error: "+error.code);
            switch(error.code) {
                case error.TIMEOUT:
                    console.log("A timeout occured! Please try again!");
                    break;
                case error.POSITION_UNAVAILABLE:
                    console.log('We can\'t detect your location. Sorry!');
                    break;
                case error.PERMISSION_DENIED:
                    console.log('Please allow geolocation access for this to work.');
                    break;
                case error.UNKNOWN_ERROR:
                    console.log('An unknown error occured!');
                    break;
                default:
                    console.log('An unknown??? error occured!');
                    break;
            }
            //如果不能通过H5 api定位,则采用后台IP定位结果确定用户位置
            if(GwkMapController.init_user_lat != 0 && GwkMapController.init_user_lng != 0){
                GwkMapController.helper.queryUserAddress(GwkMapController.init_user_lat,GwkMapController.init_user_lng);
                return;
            } else {
                var _js_url = "/getUserLocation";
                $.ajax({
                    url: _js_url,
                    dataType: "json",
                    cache:true,
                    //async:false,
                    success: function(data){
                        var lat = data.lat;
                        var lng = data.lng;
                        var address = data.address;
                        GwkMapController.userAddress = address;
                        GwkMapController.init_user_lat = lat;
                        GwkMapController.init_user_lng = lng;
                        GwkMapController.init.initCoords();
                        GwkMapController.helper.queryUserAddress(GwkMapController.init_user_location.lat,GwkMapController.init_user_location.lng);
                        console.log("根据IP获取用户地址成功:" + GwkMapController.helper.obj2string(data));
                    },
                    error: function(e){
                        console.log("根据IP获取用户地址失败!" + e);
                    }
                });
            }
        },

        queryUserAddress: function(lat, lng){
            var address = '';
            if(GwkMapController.userAddress != null && GwkMapController.userAddress != ''
                && ('CN' == GwkMapController.userCountryCode
                    || 'TW' == GwkMapController.userCountryCode
                    || 'HK' == GwkMapController.userCountryCode)){

                address = GwkMapController.userAddress;
                $('#welcome-search-input').val(address);

            } else {
                var geocoder = L.mapbox.geocoder('mapbox.places');
                geocoder.reverseQuery({ lat: lat, lng: lng }, function(err, data){
                    console.log("mapbox geocoder result: ");
                    console.log(data);
                    if(data != null && undefined != data && data.features[0]){
                        if(data.features[1]){
                            address = data.features[1].place_name;
                        } else {
                            address = data.features[0].place_name;
                        }
                        GwkMapController.userAddress = address;
                        $('#welcome-search-input').val(address);
                    }
                });
            }
        },

        setUserLocationPin: function(lat, lng, message){

            GwkMapController.init_user_location = L.latLng(lat, lng);
            var user_pos_marker = GwkMapController.userMarker;
            if(user_pos_marker != null || undefined != user_pos_marker){
                GwkMapController.GwkUserLayer.removeLayer(user_pos_marker);
            }
            user_pos_marker = L.marker([lat,lng],{
                id: 'user_position',
                title: '',
                riseOnHover: true,
                draggable: false,
                icon: L.mapbox.marker.icon({
                    'marker-size': 'large',
                    'marker-symbol': 'star',
                    'marker-color': '#7ec9b1'
                })}
            );

            //设置弹出框
            // var user_pos_popup = L.popup({closeButton:true, maxWidth:400})
            //     .setLatLng(lat,lng)
            //     .setContent(message);
            // user_pos_marker.bindPopup(user_pos_popup);

            //设置鼠标悬停效果
            user_pos_marker.on('mouseover', function(e) {
                this.openPopup();
            });
            user_pos_marker.on('mouseout', function(e) {
                //this.closePopup();
            });
            user_pos_marker.addTo(GwkMapController.GwkUserLayer);
            setTimeout(function () {
                user_pos_marker.openPopup();
            }, 700);

            GwkMapController.userMarker = user_pos_marker;
        },


        //显示用户大概位置
        showImpreciseLocation: function (err, data){
            //var _html = "<p>您的大概位置</p><span>想去:<input type='text' placeholder='城市、地区、地址...' size='20'></span><br><br><p align='center'><a id='user-search-around' href='#' " +
            //    "onclick='$(\"#panel-content-dest\").collapse(\"show\");'>探索周边</a>" +
            //    "&nbsp;&nbsp;&nbsp;&nbsp;<a href='#'>高级搜索</a></p>";
            var _html = "您大概的位置:<br><strong>"+GwkMapController.userAddress+"</strong>";
            if (data.lbounds) {
                //GwkMapController.map.setView(bounds.getCenter(),15);
                setTimeout(function () {
                    GwkMapController.map.fitBounds(bounds);
                }, 10);
                var center = data.lbounds.getCenter();
                GwkMapController.helper.setUserLocationPin(center.lat, center.lng,_html);
            } else if (data.latlng) {
                GwkMapController.map.setView([data.latlng[0], data.latlng[1]], 15);
                GwkMapController.helper.setUserLocationPin(data.latlng[0], data.latlng[1],_html);
            } else {
                console.log("无法获取用户位置:"+err);
            }
        },
        //转到指定的地点
        moveToPlace: function(place){
            var lat = place.lat;
            var lng = place.lon;
            //var bounds = place.bounds;
            var name = place.name;
            GwkMapController.userAddress = place.name;
            var _html = "为您搜索<br>"+name+"<br>附近的目的地";
            if (place.bounds) {
                var southWest = L.latLng(place.bounds[1], place.bounds[0]);
                var northEast= L.latLng(place.bounds[3], place.bounds[2]);
                var bounds = L.latLngBounds([southWest, northEast]);
                //GwkMapController.map.setView(bounds.getCenter(),15);
                //GwkMapController.map.fitBounds(bounds);
                setTimeout(function () {
                    GwkMapController.map.fitBounds(bounds);
                }, 10);

                console.log('fit bounds...');
            } else  {
                GwkMapController.map.setView([lat,lng], 13);
                console.log('fit point...');
            }
            GwkMapController.helper.setUserLocationPin(lat, lng,_html);


        },

        //在卫星视图和街道视图间切换
        swichLayers : function(_type) {
            //var _req_type = "mapbox."+_type;
            var _req_type = 'mapbox://styles/mapbox/'+_type;
            var _exists = false;
            GwkMapController.GwkBaseMapGroup.eachLayer(function (_layer){
                //console.log("-----------"+GwkMapController.helper.writeObj(_layer._tilejson));
                var _id = _layer._tilejson.id;

                //console.log("-----------request type = "+ _req_type);
                //console.log("-----------layer id = "+ _id);
                if(_id == _req_type){
                    _exists = true;
                    _layer.bringToFront();
                } else {
                    _layer.bringToBack();
                    GwkMapController.GwkBaseMapGroup.removeLayer(_layer);
                }
            });

            if(!_exists){
                if(_type == 'run-bike-hike'){
                    L.mapbox.tileLayer('mapbox.'+_type, {detectRetina:true}).addTo(GwkMapController.GwkBaseMapGroup).bringToFront();
                } else {
                    L.mapbox.styleLayer(_req_type,{detectRetina:true}).addTo(GwkMapController.GwkBaseMapGroup).bringToFront();
                }
            }

        },

        attempt: function (_func) {
            try {
                if ("function" == typeof _func) {
                    _func();
                }
            } catch (e) {
                console.log(e);
            }
        },

        optSelect: function (_obj) {
            //ok, we need this to be the input(state)
            var _self = $(_obj);
            _self.val('1');
            _self.parent().css('background-color', '#548F1E').css('color', 'white').css('font-style', 'oblique');
        },

        optUnSelect: function (_obj) {
            var _self = $(_obj);
            _self.val(0);
            _self.parent().css('background-color', 'white').css('color', '#548F1E').css('font-style', 'normal');
        },

        strip_tags: function (input, allowed) {
            //console.log("input = " + input);
            allowed = (((allowed || '') + '')
                .toLowerCase()
                .match(/<[a-z][a-z0-9]*>/g) || [])
                .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
            return input.replace(commentsAndPhpTags, '')
                .replace(tags, function ($0, $1) {
                    return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
                });
        },

        sub: function (str, n) {
            var r = /[^\x00-\xff]/g;
            if (str.replace(r, "mm").length <= n) {
                return str;
            }
            var m = Math.floor(n / 2);
            for (var i = m; i < str.length; i++) {
                if (str.substr(0, i).replace(r, "mm").length >= n) {
                    return str.substr(0, i) + "...";
                }
            }
            return str;
        },

        writeObj : function (obj) {
            var description = "";
            for(var i in obj){
                var property=obj[i];
                description+=i+" = "+property+"\n";
            }
            return description;
        },

        obj2string : function(o) {
            if(o == null) return null;
            var r=[];
            if(typeof o=="string"){
                return "\""+o.replace(/([\'\"\\])/g,"\\$1").replace(/(\n)/g,"\\n").replace(/(\r)/g,"\\r").replace(/(\t)/g,"\\t")+"\"";
            }
            if(typeof o=="object"){
                if(!o.sort){
                    for(var i in o){
                        r.push(i+":"+GwkMapController.helper.obj2string(o[i]));
                    }
                    if(!!document.all&&!/^\n?function\s*toString\(\)\s*\{\n?\s*\[native code\]\n?\s*\}\n?\s*$/.test(o.toString)){
                        r.push("toString:"+o.toString.toString());
                    }
                    r="{"+r.join()+"}";
                }else{
                    for(var i=0;i<o.length;i++){
                        r.push(GwkMapController.helper.obj2string(o[i]))
                    }
                    r="["+r.join()+"]";
                }
                return r;
            }
            return o.toString();
        },

        showMsg: function(message, type){
            Messenger.options = {
                extraClasses: 'messenger-fixed messenger-on-bottom messenger-on-right',
                theme: 'flat'
            };

            Messenger().post({
                message: message,
                type: type,
                showCloseButton: true
            });
        },

        loadScripts: function(){
            if(GwkMapController.scriptLoaded == true
                || GwkMapController.scriptLoaded == 'error'
                || GwkMapController.scriptLoaded == 'loading') {
                console.log('mapbox JS loading status:' + GwkMapController.scriptLoaded);
                return;
            } else {
                var main_js_url     = GwkMapController.asset_path + '/scripts/lib/mapbox/mapbox-v2.4.0.js';
                var plugin_js_1_url = GwkMapController.asset_path + '/scripts/lib/mapbox/plugin/leaflet.markercluster-v0.4.0.js';
                GwkMapController.scriptLoaded = 'loading';
                try{
                    $.ajax({
                        url: main_js_url,
                        dataType: "script",
                        cache:true,
                        success: function(){
                            console.log('load ' + main_js_url + "ok!");
                            $.ajax({
                                url: plugin_js_1_url,
                                dataType: "script",
                                cache:true,
                                success: function(){
                                    GwkMapController.scriptLoaded = true;
                                    GwkMapController.init.initMapKey();
                                    console.log('load ' + plugin_js_1_url + "ok!");
                                },
                                error: function(){
                                    GwkMapController.scriptLoaded = 'error';
                                    console.log("can NOT load script : " + plugin_js_1_url );
                                }
                            });
                        },
                        error: function(){
                            console.log("can NOT load script : " + main_js_url );
                            GwkMapController.scriptLoaded = 'error';
                        }
                    });
                } catch (e){
                    GwkMapController.scriptLoaded = 'error';
                    return;
                }
            }

            //if(GwkMapController.scriptLoaded == 'loading' || GwkMapController.scriptLoaded == false){
            //    console.log('mapbox JS loading status:' + GwkMapController.scriptLoaded);
            //    setTimeout(function(){
            //        GwkMapController.helper.loadScripts();
            //    }, 200);
            //}
        },
    },

    /*
     toggle functions for map menu
     */
    toggle: {
        toggleDestPanel: function (_node) {
            // GwkMapController.toggle.togglePanel('panel-dests');
        },

        togglePoiPanel: function () {
            GwkMapController.toggle.togglePanel('panel-pois');
        },

        toggleActivityRcmdPanel: function (_node) {
            GwkMapController.toggle.togglePanel('panel-act-recmd');
        },

        toggleSearchPanel: function (_node) {
            GwkMapController.toggle.togglePanel('panel-search');
        },

        togglePanel: function (_id, _node) {
            $('.panel-to-toggle').slideUp();

            $('.btn-to-toggle').each(function () {
                GwkMapController.helper.optUnSelect(this);
            });
            GwkMapController.helper.optSelect($(_node).find('input.ctr-btn-state'));

            if ($('#'.concat(_id)).is(':hidden')) {
                $('#'.concat(_id)).slideDown();
            } else {
                $('#'.concat(_id)).slideUp();
                GwkMapController.helper.optUnSelect($(_node).find('input.ctr-btn-state'));
            }
        },

        toggleNationalParkData: function (_node) {
            GwkMapController.render.resetUserLayers();

            if ('1' == $('input#toggleNP').val()) {
                //GwkMapController.GwkUtilLayer.eachLayer(function(layer) {
                //    if (layer instanceof L.Marker) {
                //        alert('got one! ');
                //        GwkMapController.GwkUtilLayer.removeLayer(layer);
                //        GwkMapController.map.removeLayer(layer);
                //    }
                //});
                //GwkMapController.GwkUtilLayer.bringToBack();
                //GwkMapController.GwkUtilLayer.setZIndex(-999);
                GwkMapController.GwkUtilLayer.clearLayers();
                GwkMapController.map.removeLayer(GwkMapController.GwkUtilLayer);
                $('input#toggleNP').val("0");
                $(_node).css('border-bottom', '2px solid #fff').css('font-weight', 'bold');
            } else {
                $('body').css('cursor', "wait");
                //$.get(
                //    '/user/ws/'.concat($.base64.encode(JSON.stringify({
                //        func: 'pull_np'
                //    }))),
                //    function (_data) {
                //        GwkMapController.GwkUtilLayer = L.mapbox.featureLayer().addTo(GwkMapController.map);
                //
                //        GwkMapController.render.renderDests(_data, GwkMapController.GwkUtilLayer, true, "gwk-mapbox-pin-dest-np");
                //        $('body').css('cursor', "auto");
                //    });
                //动态加载相应的POI JS缓存

                var _type="national_park";
                var _js_url = "/jss/"+ $.base64.encode(_type);
                $.ajax({
                    url: _js_url,
                    dataType: "script",
                    cache:true,
                    success: function(){
                        var _data = eval(_type);
                        GwkMapController.GwkUtilLayer = L.mapbox.featureLayer().addTo(GwkMapController.map);

                        GwkMapController.render.renderDests(_data, GwkMapController.GwkUtilLayer, true, "gwk-mapbox-pin-dest-np");
                        $('body').css('cursor', "auto");
                    },
                    error: function(){
                        console.log("can NOT load script : " + _js_url );
                    }
                });
                $('input#toggleNP').val("1");
                $(_node).css('border-bottom', '2px solid #fbb120').css('font-weight', 'normal');

            }
        },

        triggerTogglePOIData: function(_type){
            $("li.sport_type").each(function(){
                var sport_type = $(this).find('input.sport_type_code').val();
                if(sport_type == _type){
                    $(this).trigger('click');
                }
            });
        },

        togglePOIData: function (_type) {
            try {
                GwkMapController.render.resetUserLayers();
                var _node = $("#sport-type-"+_type);
                var _state_node = $(_node).find('input.togglePoiState');
                //var _ipt_state=$('.iptPoiState').val();
                var _poiCluster = GwkMapController.GWKPOIClusterCache[_type];
                var _searchCluster = GwkMapController.GWKSearchCache[_type];


                if ('1' == _state_node.val()) { //hide poi type layer

                    if(_poiCluster != null && undefined != _poiCluster){
                        GwkMapController.GwkPOIClusterGroup.removeLayer(_poiCluster);
                    }
                    if(_searchCluster != null && undefined != _searchCluster){
                        GwkMapController.GwkPOIClusterGroup.removeLayer(_searchCluster);
                    }

                    _state_node.val("0");
                   // $(_node).css('border-bottom', '2px solid #fff').css('font-weight', 'bold');
                   $(_node).css('background-color', '#fff');
                } else { // show poi type layer

                    if(_poiCluster != null && undefined != _poiCluster){
                        if(!GwkMapController.GwkPOIClusterGroup.hasLayer(_poiCluster)){
                            GwkMapController.GwkPOIClusterGroup.addLayer(_poiCluster);
                            //缩放地图到里用户中心最近的10个POI
                            //var all_markers = _poiCluster.getLayers();
                            ////console.log(_type +" retrieved markers count = "+all_markers.length);
                            //all_markers.sort(function(a, b){
                            //    var distance_a = a.getLatLng().distanceTo(GwkMapController.init_user_location);
                            //    var distance_b = b.getLatLng().distanceTo(GwkMapController.init_user_location);
                            //    return distance_a - distance_b;
                            //});
                            //var _closet_lagLngs = [];
                            //var _bound_count = all_markers.length > 10 ? 10 : all_markers.length;
                            //for(var j=0; j<_bound_count; j++){
                            //    _closet_lagLngs.push(all_markers[j].getLatLng());
                            //}
                            //GwkMapController.render.boundToLatLngs(_closet_lagLngs);
                        }
                    } else {

                        //同时取消搜索栏同样项目的搜索结果
                        GwkMapController.GwkPOIClusterGroup.removeLayer(_poiCluster);
                        if(_searchCluster != null && undefined != _searchCluster){
                            GwkMapController.GwkPOIClusterGroup.removeLayer(_searchCluster);
                        }

                        //如果之前没有创建过对应type的图层,则从后台加载数据并创建和渲染
                        $('body').css('cursor', "wait");
                        //动态加载相应的POI JS缓存
                        $("#map-loading").removeClass("hide");
                        var _js_url = "/jss/"+ $.base64.encode(_type);
                        $.ajax({
                            url: _js_url,
                            dataType: "text",
                            cache:true,
                            success: function(data){
                                var _data = RawDeflate.inflate($.base64.decode(data));
                                var data_array = eval("("+_data+")");
                                console.log("Get "+data_array.length+" data of POI type:" + _type);
                                $("#map-loading").addClass("hide");
                                GwkMapController.render.renderPois(_type, data_array,false);

                                console.log("load and render POI ok!!!!!!");
                            },
                            error: function(e){
                                $("#map-loading").addClass("hide");
                                console.log("can NOT load script : " + _js_url +", err_msg="+e);
                            }
                        });

                        $('body').css('cursor', "auto");
                    }
                    _state_node.val("1");
                    $(_node).css('background-color', '#42ce7b');
                }
            } catch (e) {
                console.log(e);
            }
        },

        toggleSearchResult: function(_type, _toggle){
            var clusterLayer = GwkMapController.GWKSearchCache[_type];
            if(clusterLayer != null && undefined != clusterLayer){
                if(!_toggle){
                    GwkMapController.GwkPOIClusterGroup.removeLayer(clusterLayer);
                } else {
                    if(!GwkMapController.GwkPOIClusterGroup.hasLayer(clusterLayer)){
                        GwkMapController.GwkPOIClusterGroup.addLayer(clusterLayer)
                    }
                }
            }

        }
    }, //end of toggle

    render: {
        renderDests: function (_data, _layer, _enable_bound, _type_name) {
            GwkMapController.render.resetUserLayers();
            if (_data) {
                _layer.clearLayers();
                _locs = [];
                for (var i = 0; i < _data.length; i++) {
                    try {
                        var _tmp_loc = _data[i];
                        //console.log(GwkMapController.helper.obj2string(_tmp_loc));
                        if (0 == _tmp_loc.latitude.length) continue;

                        var _pin_loc = L.latLng(_tmp_loc.latitude, _tmp_loc.longitude);
                        var _title = _tmp_loc.hasOwnProperty('name') ? _tmp_loc.name : (_tmp_loc.hasOwnProperty('title') ? _tmp_loc.title : '没有标题');
                        var _link ='<a href="' + GwkMapController.site_url + 'place/index/pid/' + _tmp_loc.pid + '">'
                            + _title + '</a>';
                        var _pin = null;
                        var _pin_type_name = "gwk-mapbox-pin-dest-default";
                        if (null != _type_name) {
                            _pin_type_name = _type_name;
                        } else if(_tmp_loc.hasOwnProperty('sport_id')) {
                            _pin_type_name = "gwk-pin-dest-" + _tmp_loc.sport_id;
                        }

                        var _pin_content = GwkMapController.helper.strip_tags(_tmp_loc.description).substr(0, 80) + "...";

                        _pin = GwkMapController.render.buildNationalParkPin(_tmp_loc.pid ,_tmp_loc.latitude,_tmp_loc.longitude, _title, _pin_type_name,_pin_content);

                        _pin.addTo(_layer);


                    } catch (e) {
                        //alert("error!");
                        console.log(e);
                    }
                } // end of for

                _layer.bringToFront();

                //if (_enable_bound && 0 < _data.length) {
                //    if (1 < _locs.length) {
                //        var viewBoundaries = Microsoft.Maps.LocationRect.fromLocations(_locs);
                //        // GwkMapController.map.setView({bounds: viewBoundaries, padding: 100});
                //
                //    } else if (1 == _locs.length) {
                //        GwkMapController.map.setView({center: _locs[0]});
                //    }
                //}

                //return _layer;
            }
        },

        //搜索指定范围内的POI并且渲染
        searchBoundsAndRender : function (_types, bounds){
            var data = JSON.stringify({
                poi_types:_types,
                left_up:{lat: bounds.getNorth(), lng:bounds.getWest()},
                right_down:{lat: bounds.getSouth(), lng:bounds.getEast()}
            });

            $('body').css('cursor', "wait");
            //动态加载相应的POI JS缓存
            $("#map-loading").removeClass("hide");
            var _js_url = "/searchPOIsByBounds/"+ data;
            $.ajax({
                url: _js_url,
                dataType: "text",
                cache:true,
                success: function(data){
                    var data_array = eval("("+data+")");
                    console.log("Get "+data_array.length+" data of POI type:" + _types);
                    GwkMapController.render.renderPois(_types, data_array,false);
                    $("#map-loading").addClass("hide");
                    console.log("load an d render ok!!!!!!");
                },
                error: function(e){
                    $("#map-loading").addClass("hide");
                    console.log("can NOT load script : " + _js_url +", err_msg="+e);
                }
            });

            $('body').css('cursor', "auto");

        },

        //搜索指定半径范围内的POI并且渲染(首页欢迎搜索框的入口)
        searchRadiusAndRender : function (_types){
            var lat = GwkMapController.init_user_location.lat;
            var lng = GwkMapController.init_user_location.lng;
            var data = JSON.stringify({
                poi_types:_types,
                lat: lat,
                lng: lng
            });

            $('body').css('cursor', "wait");
            //AJAX方式从后台搜索并渲染搜索结果
            $("#map-loading").removeClass("hide");
            var _js_url = "/searchPOIsByRadius/"+ data;
            $.ajax({
                url: _js_url,
                dataType: "text",
                cache:true,
                success: function(data){
                    $("#map-loading").addClass("hide");
                    var data_array = eval("("+data+")");
                    var count = +data_array.length;
                    console.log("Get "+data_array.length+" data of POI type:" + _types);
                    //if(count == 0){
                    //    GwkMapController.helper.setUserLocationPin(lat,lng,"抱歉, 在: <strong>"+GwkMapController.userAddress+" </strong><br>周边500公里范围内, 没能找到您想要的目的地.<br>"+more_html);
                    //    return;
                    //} else {
                    //
                    //    GwkMapController.helper.setUserLocationPin(lat,lng,"在: <strong>"+GwkMapController.userAddress+" </strong><br>周边500公里范围内, 为您找到"+count+"个目的地");
                    //}
                    var poiLayers = [];
                    var latLngs = [];
                    var poiCountArr = [];
                    for(var i=0; i<count; i++){
                        var poi = data_array[i];
                        var type = poi.sport_type;
                        var poiCount = poiCountArr[type];
                        poiCount = $.isNumeric(poiCount) ? poiCount+1 : 1;
                        poiCountArr[type] = poiCount;
                        var latLng = L.latLng(poi.lat,poi.lon);
                        //console.log(poi.id + " 离用户选点距离" + latLng.distanceTo(GwkMapController.init_user_location));
                        latLngs.push(latLng);
                        var clusterGroup = GwkMapController.GWKSearchCache[type];
                        var poiLayer = poiLayers[type];
                        if(clusterGroup == null || undefined == clusterGroup){
                            clusterGroup = GwkMapController.pin.cluster(type);
                            GwkMapController.GWKSearchCache[type] = clusterGroup;
                        }
                        if(poiLayer == null || undefined == poiLayer){
                            poiLayer = L.mapbox.featureLayer();
                            poiLayers[type] = poiLayer;
                            poiLayer.on('layeradd', GwkMapController.render.onPoiAddToMap);
                        }
                        var pin = GwkMapController.pin.poi(poi);
                        poiLayer.setGeoJSON(pin);
                        clusterGroup.addLayer(poiLayer);
                        GwkMapController.GwkPOIClusterGroup.addLayer(clusterGroup);

                    }//end for

                    var _width = _types.length * 50 + 5;
                    var more_html = '<div id="categories-view" class="clearfix" style="width: '+_width+'px;height: 80px;margin-top: -3px;"><div id="categories"><ul>';
                    //为POI增加事件
                    for(var k=0; k<_types.length; k++){
                        var _cluster = GwkMapController.GWKSearchCache[_types[k]];
                        if(_cluster != null && undefined != _cluster){
                            _cluster.on('mouseover', GwkMapController.render.poiMarkersOnMouseOver);
                            _cluster.on('click', GwkMapController.render.poiMarkersOnClick);
                        }
                    }

                    for(var i= 0; i<tags_str.length; i++){
                        var _tag = tags_str[i];
                        for(var j= 0; j<_types.length; j++){
                            var _type=_types[j];
                            if(_type == _tag.code){
                                var _poiCount = poiCountArr[_type];
                                _poiCount = $.isNumeric(_poiCount) ? _poiCount : 0;
                                more_html = more_html+'<li style="margin-left:10px;" ><div class="marker-cluster gwk-cluster-icon-'+_type+'" onclick="javascript: GwkMapController.toggle.triggerTogglePOIData(\''+_type+'\')"></div><div class="cluster-number">'+ _poiCount +'</div>'+ _tag.name_cn +'<input type="checkbox" value="'+_type+'" onchange="javascript: GwkMapController.toggle.toggleSearchResult(this.value, this.checked)" checked></li>';
                            }
                        }
                    }
                    more_html = more_html+'</ul></div></div><p style="text-align: center; font-size: 14px; font-weight: 200; color:#7d7d7d; margin-bottom: -15px">(点击图标可查看<strong style="color: black">全球</strong>目的地)</p><p style="text-align: center; font-size: 14px; font-weight: 200; color:#7d7d7d">';
                    GwkMapController.helper.setUserLocationPin(lat,lng,"在: <strong>"+GwkMapController.userAddress+" </strong><br>周边500公里范围内, 为您找到"+count+"个目的地:"+more_html);
                    GwkMapController.render.boundToLatLngs(latLngs);

                    console.log("load and render ok!!!!!!");
                },
                error: function(e){
                    $("#map-loading").addClass("hide");
                    console.log("can NOT load script : " + _js_url +", err_msg="+e);
                }
            });

            $('body').css('cursor', "auto");

        },

        closest_pois : [],//用于存储离用户最近一组的POI
        closest_markers : [],//用于存储离用户最近一组的Marker

        renderPois: function (_type, _data, _is_search) {
            GwkMapController.render.resetUserLayers();
            //如果此时没有获取用户位置,则设置一个默认的位置
            if(GwkMapController.init_user_location == null){
                GwkMapController.init_user_location = L.latLng(GwkMapController.default_location[0],GwkMapController.default_location[1]);
            }
            if (_data) {

                var _poiLayer = L.mapbox.featureLayer();
                var _clusterGroup = GwkMapController.pin.cluster(_type);

                if(_is_search){
                    GwkMapController.GWKSearchCache[_type] = _clusterGroup;

                } else {
                    GwkMapController.GWKPOIClusterCache[_type] = _clusterGroup;
                }

                var _locs = [];
                var _pins = [];
                //GwkMapController.render.closest_pois = [];//用于存储离用户最近一组的POI
                //GwkMapController.render.closest_markers = [];//用于存储离用户最近一组的Marker

                for (var i = 0; i < _data.length; i++) {
                    try {

                        var _tmp_loc = _data[i];

                        //console.log(_tmp_loc);
                        $.each(tags_str, function(i, tags) {
                            if(_tmp_loc.sport_type==tags.code){
                                _tmp_loc.icon_class="gwk-mapbox-pin-dest-"+tags.code;
                            }
                        });

                        //console.log("POI DATA :" +  GwkMapController.helper.obj2string(_tmp_loc));
                        //console.log(_tmp_loc);
                        if(_tmp_loc.lat == null || _tmp_loc.lon == null
                            || _tmp_loc.lat=='' || _tmp_loc.lon == ''
                            || _tmp_loc.lat=='None' || _tmp_loc.lon == 'None'){
                            continue;
                        }
                        var _poi_latlng = L.latLng(_tmp_loc.lat,_tmp_loc.lon);
                        var _distance = _poi_latlng.distanceTo(GwkMapController.init_user_location);
                        //var _distance = _poi_latlng.distanceTo(GwkMapController.map.getCenter());
                        _tmp_loc["distance"] = _distance;
                        //判断是否保存为离用户最近的一组POI
                        //GwkMapController.render.closest_pois = GwkMapController.render.getNearbyPOIs(GwkMapController.render.closest_pois,_tmp_loc);

                        var _pin = GwkMapController.pin.poi(_tmp_loc);
                        _pins.push(_pin);

                        if (GwkMapController.enable_bound) {
                            //_locs.push(_pin.getLocation());
                        }

                    } catch (e) {
                        console.log(e);
                        console.log("bad data, poi id="+_tmp_loc.id);
                    }

                } // end for

                console.log("-------avialable POI Count = " + _pins.length);
                //console.log(_closest_pois);
                try {
                    //var bounds = GwkMapController.map.getBounds();

                    //使用动态注入icon的方法以解决Mapbox不能对divIcon实施filter的问题
                    console.log("----  before set events" + new Date());
                    _clusterGroup.on('mouseover', GwkMapController.render.poiMarkersOnMouseOver);
                    _clusterGroup.on('click', GwkMapController.render.poiMarkersOnClick);
                    _poiLayer.on('layeradd', GwkMapController.render.onPoiAddToMap);

                    GwkMapController.GwkPOILayerCache[_type] = _poiLayer;

                    console.log("----  before setGeoJSON()" + new Date());
                    var _len = _pins.length;
                    var i = 0, _cut= 500, t=0;
                    //_poiLayer.setGeoJSON(_pins);
                    //按距离对POI GeoJSON排序
                    _pins = _pins.sort(function(a,b){
                        return a.properties.distance - b.properties.distance;
                    });
                    //获取前10个最近的POI坐标并缩放地图
                    var _closet_lagLngs = [];
                    var _bound_count = _pins.length > 10 ? 10 : _pins.length;
                    for(var j=0; j<_bound_count; j++){
                        var _coords = _pins[j].geometry.coordinates;
                        _closet_lagLngs.push(L.latLng(_coords[1],_coords[0]));
                    }
                    GwkMapController.render.boundToLatLngs(_closet_lagLngs);

                    if(_len > 500){
                        //使用非独占方式分批渲染, 避免页面卡死
                        var addGeoJSON = function(){
                            var _from = i*_cut;
                            var _to = _from + _cut-1;
                            var _end = false;
                            if(_to > _len-1){
                                _to = _len -1;
                                _end = true;
                            }
                            i++;
                            //console.log("add GeoJson to layer: from " + _from + ", to " + _to);
                            var _sub = _pins.slice(_from, _to);
                            //console.log("----  before set GeoJSON:" + new Date());
                            _poiLayer.setGeoJSON(_sub);
                            //console.log("----  after set GeoJSON:" + new Date());
                            _clusterGroup.addLayer(_poiLayer);
                            //console.log("----  after add to cluster:" + new Date());

                            if(_end){
                                window.clearInterval(t);
                            }
                        };
                        t = window.setInterval(addGeoJSON,100);
                    } else {
                        _poiLayer.setGeoJSON(_pins);
                        _clusterGroup.addLayer(_poiLayer);
                    }

                    //增强显示效果以提示用户
                    if(_len == 1){
                        console.log('只有一条结果时,自动显示气泡...');
                        setTimeout(function(){
                            _poiLayer.eachLayer(function(layer) {
                                if (layer instanceof L.Marker) {
                                    console.log('Got one L.Marker instance...');
                                    console.log($(layer._icon).attr('class'));
                                    //加入跳跃动画
                                    for(var i=0; i<3; i++){
                                        $(layer._icon).animate({'margin-top':'-55px'},400)
                                                      .animate({'margin-top':'-42px'},100)
                                                      .animate({'margin-top':'-42px'},500);
                                    }
                                    layer.fireEvent('mouseover');
                                }
                            });
                        },500);
                    }

                    console.log("----  before GwkPOIClusterGroup.addLayer()" + new Date());
                    GwkMapController.GwkPOIClusterGroup.addLayer(_clusterGroup);
                    console.log("----  after GwkPOIClusterGroup.addLayer()" + new Date());


                } catch(e){ console.log(e)}

                //if (GwkMapController.enable_bound && 0 < GwkMapController.render.closest_markers.length) {
                //    //定位地图到离用户最近的一组POI
                //    GwkMapController.render.boundToPOIMarkers(GwkMapController.render.closest_markers,true, _is_search, _type);
                //}

            } // end of if(_data)
        }, // end of renderPois()

        //鼠标移到地图上的POI点时的事件响应方法
        poiMarkersOnMouseOver: function(e){
            var marker = e.layer;
            var feature = marker.feature;
            var _id = feature.properties.poi_id;
            var _desc = feature.properties.description;
            var _poi_type = feature.properties.poi_type;
            var _lat_lng = feature.geometry.coordinates;
            var _popup = GwkMapController.pin.popup(_poi_type,_lat_lng[1], _lat_lng[0], _desc);
            _popup.setContent(_desc);
            marker.bindPopup(_popup);
            marker.openPopup();
            GwkMapController.render.getPoiReviewInfo(_poi_type,_id, _lat_lng[1], _lat_lng[0], false);
        },

        poiMarkersOnClick: function(e){
            var marker = e.layer;
            var feature = marker.feature;
            var _id = feature.properties.poi_id;
            var _poi_type = feature.properties.poi_type;
            var _poi_link = feature.properties.poi_link;
            var _lat_lng = feature.geometry.coordinates;
            if(_poi_type == "org"){
                marker.openPopup();
            } else {
                var window_width= $(window).width();
                //对于小屏幕设备(移动设备,点击时弹出气泡,而非进入目的地页面)
                if(window_width > 736){
                    window.open(_poi_link);
                } else {
                    //小屏幕设备点击后, 下移地图以留出空间显示完整气泡
                    var zoom = GwkMapController.map.getZoom();
                    if(zoom<2){
                        zoom = 2;
                        GwkMapController.map.setZoom(zoom);
                    }
                    var center = GwkMapController.map.latLngToContainerPoint(marker.getLatLng());
                    //console.log('container x='+ center.x + ', y='+center.y);
                    center.y = center.y - 100;
                    var center_latlng = GwkMapController.map.containerPointToLatLng(center);
                    //var polyline = L.polyline([marker.getLatLng(),center_latlng]).addTo(GwkMapController.map);
                    setTimeout(function () {
                        GwkMapController.map.panTo(center_latlng);
                    }, 100);
                    //异步加载review信息及图片
                    GwkMapController.render.getPoiReviewInfo(_poi_type,_id, _lat_lng[1], _lat_lng[0], false);
                    marker.openPopup();
                }
            }
        },

        //POI添加到地图的事件响应方法
        onPoiAddToMap: function(e){
            var marker = e.layer;
            var feature = marker.feature;
            var _id = feature.properties.poi_id;
            var _poi_type = feature.properties.poi_type;
            var _icon = GwkMapController.pin.icon(_poi_type);
            // This is where the magic happens...
            marker.setIcon(_icon);
            var markerCaches = GwkMapController.GWKMarkerCache[_poi_type];
            if(markerCaches == null || undefined == markerCaches){
                markerCaches = new Array();
                GwkMapController.GWKMarkerCache[_poi_type] = markerCaches;
            }
            markerCaches['_'+_id] = marker;
            //console.log(_poi_type+": "+markerCaches.length + " added to cache ...");
            //检查Marker是否在离用户最近的一组POI里,如果是就保存下来
            //for(var i=0; i<GwkMapController.render.closest_pois.length; i++){
            //    var _tmp_poi = GwkMapController.render.closest_pois[i];
            //    if(_id == _tmp_poi.id){
            //        GwkMapController.render.closest_markers.push(marker);
            //    }
            //}
        },

        //重置用户图层(清除临时的线,恢复定位图标)
        resetUserLayers: function(){

            GwkMapController.GwkUserLayer.clearLayers();
            if(GwkMapController.userMarker != null && undefined != GwkMapController.userMarker){
                GwkMapController.userMarker.addTo(GwkMapController.GwkUserLayer);
            }

        },

        //AJAX获取POI的Review数据(Review数量/想去/去过)
        getPoiReviewInfo: function(sport_type, poi_id, lat, lng, refresh){
            var markTogo= function(poi_id, is_togo){
                //判断"想去"的状态
                if (is_togo) {
                    $('#wanna-go-'+poi_id).removeClass('fa-heart-o')
                        .addClass('fa-heart')
                        .removeClass('gwk-font-white')
                        .addClass('gwk-font-red');
                    $('#wanna-go-'+poi_id).hover(function () {
                        $(this).removeClass('fa-heart-o');
                        $(this).addClass('fa-heart');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-red');
                    }, function () {
                        $(this).removeClass('fa-heart-o');
                        $(this).addClass('fa-heart');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-red');
                    });
                } else {
                    $('#wanna-go-'+poi_id).removeClass('fa-heart')
                        .addClass('fa-heart-o')
                        .removeClass('gwk-font-red')
                        .addClass('gwk-font-white');
                    $('#wanna-go-'+poi_id).hover(function () {
                        $(this).removeClass('fa-heart-o');
                        $(this).addClass('fa-heart');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-red');
                    }, function () {
                        $(this).removeClass('fa-heart');
                        $(this).addClass('fa-heart-o');
                        $(this).removeClass('gwk-font-red');
                        $(this).addClass('gwk-font-white');
                    });
                }
            };
            var markBeen= function(poi_id, is_been){
                //判断"去过"的状态
                if (is_been) {
                    $('#been-there-'+poi_id).removeClass('fa-flag-o')
                        .addClass('fa-flag')
                        .removeClass('gwk-font-white')
                        .addClass('gwk-font-green');
                    $('#been-there-'+poi_id).hover(function () {
                        $(this).removeClass('fa-flag-o');
                        $(this).addClass('fa-flag');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-green');
                    }, function () {
                        $(this).removeClass('fa-flag-o');
                        $(this).addClass('fa-flag');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-green');
                    });
                } else {
                    $('#been-there-'+poi_id).removeClass('fa-flag')
                        .addClass('fa-flag-o')
                        .removeClass('gwk-font-green')
                        .addClass('gwk-font-white');
                    $('#been-there-'+poi_id).hover(function () {
                        $(this).removeClass('fa-flag-o');
                        $(this).addClass('fa-flag');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-green');
                    }, function () {
                        $(this).removeClass('fa-flag');
                        $(this).addClass('fa-flag-o');
                        $(this).removeClass('gwk-font-green');
                        $(this).addClass('gwk-font-white');
                    });
                }
            };
            var fillData = function(data){
                //console.log("poi review data=" + GwkMapController.helper.writeObj(data));
                var is_been = data.is_been;
                var is_togo = data.is_togo;
                var review_count = data.review_count;
                var review_score = data.review_score;
                var poi_name = data.poi_name;
                var poi_link = '/poi/' + sport_type + '/' + poi_id;

                $('#poi-name').html('');
                $('#poi-name').append(poi_name);
                $('#poi-name').attr('href',poi_link);

                $('#review-score-'+poi_id).raty({
                    starType: 'i',
                    half: false,
                    readOnly: true,
                    score: review_score
                });
                $('#review-count-'+poi_id).html(review_count);

                markTogo(poi_id,is_togo);
                markBeen(poi_id,is_been);
                GwkMapController.render.getPoiImages(sport_type,poi_id,poi_name,lat,lng);
            };

            //先从缓存中查找
            var review_data_cache = GwkMapController.GWKPOIContentCache['review_cache_'+poi_id];
            if(!refresh && review_data_cache !=null){
                //console.log('hit review_cache of poi:' + poi_id);
                fillData(review_data_cache);
                return;
            } else {
                //未命中缓存,或者需要刷新,则从后台异步取得数据进行填充
                $.ajax({
                    async: true,
                    url: '/poi/ajax/' + sport_type + '/' + poi_id,
                    dataType: "json",
                    cache: false,
                    success: function (data) {
                        if(data){
                            //进行缓存
                            GwkMapController.GWKPOIContentCache['review_cache_'+poi_id] = data;
                            //填充数据
                            fillData(data);
                        }
                    },
                    error: function () {
                        console.log("获取poi_id=" + poi_id + "的信息失败!");
                    }
                });
            }
        },

        getPoiImages : function(sport_type, poi_id, poi_name, lat, lng){
            //先从缓存中查找
            var _img_cache = GwkMapController.GWKPOIContentCache['img_cache_'+poi_id];
            if(_img_cache !=null){
                //console.log('hit img_cache of poi:' + poi_id);
                var _height = _img_cache.container_height;
                var _html = _img_cache.img_html;
                $("#"+poi_id).parent().parent().parent().css("height", _height);
                $("#"+poi_id).html(_html);
                var real_height = $("#"+poi_id).parent().parent().outerHeight(true);
                $("#"+poi_id).parent().parent().parent().parent().css("height", real_height+12);
                $("#"+poi_id).parent().parent().parent().parent().parent().css("height", real_height+27);
                return;
            }
            //如果没有命中缓存,则通过Google Places API加载一张目的地图片
            var _poi_link = $('#poi-name').attr('href');
            console.log(poi_name + ", " + sport_type);

            var showImage = function(_json_data){
                var _img_url = _json_data.img_url;
                var _img_source = _json_data.img_source;
                var _width = _json_data.img_width;
                var _height = _json_data.img_height;
                var _img_html = "<a target='_blank' href='"+_poi_link+"'><div style='position: absolute;top: 8px;width: 240px;height: 50px;background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.3));'></div><img src='"+_img_url+"' width='"+_width+"' height='"+_height+"' /></a>";
                var _img_cache = {
                    container_height: _height,
                    img_html : _img_html
                };
                GwkMapController.GWKPOIContentCache['img_cache_'+poi_id] = _img_cache;
                $("#"+poi_id).parent().parent().parent().css("height", _height);
                $("#"+poi_id).html(_img_html);
                var real_height = $("#"+poi_id).parent().parent().outerHeight(true);
                $("#"+poi_id).parent().parent().parent().parent().css("height", real_height+12);
                $("#"+poi_id).parent().parent().parent().parent().parent().css("height", real_height+27);

            };
            GwkPoiImageHelper.getOneThumbnail(sport_type,poi_id,lat,lng,poi_name,showImage);

        },//end of getPoiImages

        mark_wanna_and_been: function(sport_type,poi_id, func, lat, lng){
                $.get(
                    '/user/ws/' + $.base64.encode(JSON.stringify({
                        func  : func,
                        id    : poi_id,
                        sport_type : sport_type
                    })),
                    function(_resp) {
                        if('success' == _resp.status) {
                            //success
                            //GwkMapController.helper.showMsg(_resp.data,'info');
                            swal({
                                title: _resp.data,
                                type: 'success',
                                width: '300'
                            });
                        } else if('error' == _resp.status) {
                            //error
                           // GwkMapController.helper.showMsg(_resp.message,'error');
                            swal({
                                title: _resp.message,
                                type: 'error',
                                width: '300'
                            });
                        }

                        //刷新视图
                        GwkMapController.render.getPoiReviewInfo(sport_type,poi_id, lat, lng, true);

                });
        },

        //缩放地图到一组给定的坐标所分布的区域
        boundToLatLngs: function(latLngs){
            var firstLatLng = latLngs.length > 0 ? latLngs[0] : null;
            //需要把用户坐标也加入进去
            var window_width= $(window).width();
            //对于单点搜索, 不将用户坐标加入到bounds范围
            if(latLngs.length > 1){
                latLngs.unshift(GwkMapController.init_user_location);
            }

            var bounds = L.latLngBounds(latLngs);
            var paddingOptions = {paddingTopLeft:[0,320], paddingBottomRight:[0,0]};
            //当只有一POI个坐标时(加用户坐标位2个), fitBounds时要给弹出气泡预留显示位置
            //if(latLngs.length == 2){
            //    if(firstLatLng.lat < GwkMapController.init_user_location.lat){
            //        //如果目的地坐标在用户坐标之下
            //        if(firstLatLng.lng < GwkMapController.init_user_location.lng){
            //            //如果目的地在用户坐标左边
            //            paddingOptions = {paddingTopLeft:[120,20], paddingBottomRight:[20,20]};
            //            console.log('左下');
            //        } else{
            //            //如果目的地在用户坐标右边
            //            paddingOptions = {paddingTopLeft:[20,20], paddingBottomRight:[120,20]};
            //            console.log('右下');
            //        }
            //    } else {
            //        //如果目的地坐标在用户坐标之上
            //        if(firstLatLng.lng < GwkMapController.init_user_location.lng){
            //            //如果目的地在用户坐标左边
            //            paddingOptions = {paddingTopLeft:[120,320], paddingBottomRight:[20,20]};
            //            console.log('左上');
            //        } else{
            //            //如果目的地在用户坐标右边
            //            paddingOptions = {paddingTopLeft:[20,320], paddingBottomRight:[120,20]};
            //            console.log('右上');
            //        }
            //    }
            //} else {
            //    paddingOptions = {paddingTopLeft:[0,320], paddingBottomRight:[0,0]};
            //}
            //GwkMapController.map.setView(bounds.getCenter(),15);
            setTimeout(function () {
                GwkMapController.map.fitBounds(bounds,paddingOptions);
            }, 100);
        },

        //缩放地图到给定的一组POI的范围以显示这组POI
        boundToPOIMarkers: function(poi_markers, is_draw_line, is_search, sport_type){

            GwkMapController.GwkUserLayer.bringToFront();

            var _latlngs = [];
            for(var i=0; i<poi_markers.length; i++){
                var tmp = poi_markers[i];
                //在地图上显示这组POI
                tmp.addTo(GwkMapController.GwkUserLayer);
                var _latlng = tmp.getLatLng();
                _latlngs.push(_latlng);
            }
            var firstLatLng = _latlngs[0];
            GwkMapController.render.boundToLatLngs(_latlngs);

            var popUpContent = '';
            //是否需要画线
            if(is_draw_line){
                //以用户位置为中心向最近的POI划线:
                for(var i= 0; i<poi_markers.length; i++){
                    L.polyline([GwkMapController.init_user_location,poi_markers[i].getLatLng()],{
                        weight:2,color:'#43ce7b'
                    }).addTo(GwkMapController.GwkUserLayer);
                }
                popUpContent = "<strong>"+GwkMapController.userAddress
                             + "</strong><br>已经为您标出<select onchange='javascript:GwkMapController.render.changeSearchRadius(this.value,\""+sport_type+"\");'><option value='500' selected='true'>500公里</option><option value='1000'>1000公里</option><option value='3000'>3000公里</option><option value='∞'>全球</option></select>范围内最近的"
                             + poi_markers.length +"个目的地<br>";
            }

            if(is_search){
                var distance = firstLatLng.distanceTo(GwkMapController.init_user_location);
                var distance = parseInt(distance/1000);
                popUpContent = "<strong>"+GwkMapController.userAddress+"</strong><br>距您选择的目的地"+distance+"公里";

            }
            GwkMapController.userMarker.setPopupContent(popUpContent);
            GwkMapController.userMarker.openPopup();

        },

        refreshBounds: function(){

        },

        changeSearchRadius: function(radius,sport_type){

            var poiLayerCache = GwkMapController.GwkPOILayerCache[sport_type];
            if(poiLayerCache != null && undefined != poiLayerCache){
                $("#map-loading").removeClass("hide");
                GwkMapController.GWKPOIClusterCache[sport_type].removeLayer(poiLayerCache);
                console.log("reset filter ....");
                poiLayerCache.setFilter(function(feature){
                    var _lat_lng = feature.geometry.coordinates;
                    if(radius =='∞'){
                        return true;
                    }
                    if(GwkMapController.init_user_location.distanceTo(L.latLng(_lat_lng[1], _lat_lng[0])) < radius*1000 ){
                        return true;
                    } else {
                        return false;
                    }
                });
                $("#map-loading").addClass("hide");
                GwkMapController.GWKPOIClusterCache[sport_type].addLayer(poiLayerCache);
                //GwkMapController.GWKPOIClusterCache[sport_type].refreshClusters(poiLayerCache);
                GwkMapController.map.fitBounds(poiLayerCache.getBounds());
            }
            //$("#map-loading").addClass("hide");
        },

        //产生一个指定长度(GwkMapController.closestPOICount),且距离用户中心点最短的POI数组
        getNearbyPOIs: function(pois,poi){
            var _clone = pois.slice(0);
            if(_clone.length < 1){
                _clone.push(poi);
                return _clone;
            }
            for(var i= 0; i<pois.length; i++){
                var tmp = pois[i];
                if(poi.distance < tmp.distance
                    || _clone.length < GwkMapController.closestPOICount){
                    _clone.unshift(poi);
                    break;
                }
            }
            if(_clone.length > GwkMapController.closestPOICount){
                _clone.sort(function(a,b){
                    return a.distance < b.distance ? -1 : 1;
                });
                _clone.pop();
            }
            return _clone;
        },

        buildNationalParkPin: function (_id, _lat, _lng, _title, _className, _content) {
            var _pin = GwkMapController.render.buildPin(_lat, _lng, _title, _className,_content );

            var _popup = L.popup({closeButton:false})
                .setLatLng(_lat,_lng)
                .setContent(_content);

            _pin.bindPopup(_popup);

            _pin.on('mouseover', function(e) {
                this.openPopup();
            });

            _pin.on('click', function(e){

            });
            //_pin.on('mouseout', function(e) {
            //    this.closePopup();
            //});

            return _pin;
        }, // end of buildNationalParkPin()

        buildPin: function (_lat, _lng, _title, _className, _content ){
            var _icon = L.divIcon({
                // Specify a class name we can refer to in CSS.
                className: _className,
                // Set marker width and height
                iconSize: [35, 46],
                iconAnchor:[17.5, 42],
                popupAnchor: [-0, -48],
                shadowUrl: '/images/map_menu_icons/icon-shadow.png',
                shadowSize:[60,21]
            });

            var _pin = L.marker([_lat ,_lng], {icon: _icon, riseOnHover:true});
            return _pin;
        }, // end of buildPin()

    }, // end of render

    pin: {

        icon: function(sport_type){

            var icon = L.divIcon({
                // Specify a class name we can refer to in CSS.
                className: 'gwk-mapbox-pin-dest-'+sport_type,
                // Set marker width and height
                iconSize: [35, 46],
                iconAnchor:[17.5, 42],
                popupAnchor: [-0, -48]
            });
            return icon;
        },

        popup: function(sport_type,_lat,_lng,_content){

            switch(sport_type){
                case 'ski_resort' :
                case 'camping_ground' :
                case 'blossom' :
                case 'shooting_range' :
                case 'diving' :
                case 'skydiving' :
                case 'surfing' :
                case 'paddling' :
                case 'farm' :
                case 'fishing_charter' :
                case 'hiking' :
                case 'off_roading' :
                    var _popup = L.popup({closeButton:false, minHeight : 100})
                        .setLatLng(_lat,_lng)
                        .setContent(_content);
                    return _popup;
                    break;
                case 'activity' :
                    var _popup = L.popup({closeButton:false, minHeight : 100, maxWidth:300})
                        .setLatLng(_lat,_lng)
                        .setContent(_content);
                    return _popup;
                    break;
                case 'org' :
                    var _popup = L.popup({closeButton:false, closeButton : true, maxHeight : 300, minWidth:320})
                        .setLatLng(_lat,_lng)
                        .setContent(_content);

                    return _popup;
                    break;
                default:
                    var _popup = L.popup({closeButton:false, minHeight : 100})
                        .setLatLng(_lat,_lng)
                        .setContent(_content);
                    return _popup;
            }
        },

        poi: function(_data){
            var sport_type = _data.sport_type;
            switch(sport_type) {
                case 'ski_resort' :
                case 'camping_ground' :
                case 'blossom' :
                case 'shooting_range' :
                case 'diving' :
                case 'skydiving' :
                case 'surfing' :
                case 'paddling' :
                case 'farm' :
                case 'fishing_charter' :
                case 'hiking' :
                case 'off_roading' :
                    var _link = '/poi/' + sport_type + '/' + _data.id;
                    var _pin = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [_data.lon, _data.lat]
                        },
                        properties: {
                            title: '',
                            description: "<div id='poi-infobox' class='box box-infobox'><div>"
                            +"<div style='_height:200px; text-align:center' id='"+_data.id+"'></div>"
                            + "<div class='caption' style='width: 240px; padding:10px 8px 2px;'>"
                            +"<span style='position:absolute;top: 20px;right:20px;font-size:20px;color:white;'>"
                            +"<a href='#' role='button' class='svg'><i title='想去' id='wanna-go-"+_data.id+"' class='fa fa-heart-o wanna_go gwk-font-white'"
                            +" onclick=\"javascript: GwkMapController.render.mark_wanna_and_been('"+sport_type+"','"+_data.id+"','poi_mark_togo',"+_data.lat+","+_data.lon+")\"></i></a>&nbsp;&nbsp;"
                            +"<a href='#' role='button' class='svg'><i title='去过' id='been-there-"+_data.id+"' class='fa fa-flag-o been_there'"
                            +" onclick=\"javascript: GwkMapController.render.mark_wanna_and_been('"+sport_type+"','"+_data.id+"','poi_mark_been',"+_data.lat+","+_data.lon+")\"></i></a></span>"
                            +"<span style='width: 210px;font-size: 16px'>"
                            +"<a id='poi-name' style='color:black!important' target='_blank' href='"+_link+"'></a></span>"
                            +"<br/><span style='width: 220px;font-size: 8px;display: inline-block;'>"
                            +"<span id='review-score-"+_data.id+"' class='map-star' style='line-height: 2.2;color:#ffb432;'></span>"
                            +"<a href='"+_link+"#main_review' target='_blank'><span id='review-count-"+_data.id+"' style='font-size: 12px;margin-left: 5px'>-</span><span style='font-size: 12px;'> 评论</span></a></span></div></div>",
                            'marker-id': _data.id,
                            'marker-color': '#f86767',
                            'poi_type': sport_type,
                            poi_id: _data.id,
                            poi_link: _link,
                            distance: _data.distance
                        }
                    };

                    return _pin;
                    break;
                case 'activity' :
                    var _link = GwkMapController.site_url + 'activity/' + _data.id + '/' + _data.plan_id;
                    var _special_sales = _data.special_sale_flag;
                    var _promotion_flag = _data.promotion_flag;
                    var _special_html = "";
                    if(_special_sales){
                        _special_html ="<div class='special-sales-logo-map'><b>特惠</b></div>";
                    }
                    var _currency = _data.currency.toLowerCase();
                    var _start_date = new Date( _data.start_time);
                    var _start_date_str = _start_date.getMonth()+1+"月"+_start_date.getDate()+"日";
                    var _price = _data.bottom_price;
                    var _activity_type = _data.type;
                    var _price_str = "";
                    if(_activity_type == 'free'){
                        _price_str = "免费";
                        _currency="";
                    } else {
                        if(_price == null || _price =='null' || undefined==_price){
                            _price = 0;
                        } else {
                            try{
                                var temp1 = parseInt(_price);
                                var temp2 = parseFloat(_price);
                                _price = temp1 == temp2 ? temp1 : _price;
                            } catch(e) {}
                        }
                        _price_str = _price+"<span style='font-size: 12px;padding-left: 2px;'>起<span/>";
                    }
                    var _period_str = "";
                    try{
                        var _days = parseInt(_data.duration_days);
                        var _nights = parseInt(_data.duration_nights);
                        if(_days >0){
                            _period_str = _days+"天";
                        }
                        if(_nights > 0){
                            _period_str += _nights+"晚";
                        }
                    } catch(e){
                        console.log(e);
                    }
                    var act_features_str = '';
                    if(_data.extend_labels != null && JSON.parse(_data.extend_labels) != null){
                        console.log(JSON.parse(_data.extend_labels).features);
                        var act_features = JSON.parse(_data.extend_labels).features;
                        var len = 3;
                        if(act_features == null || act_features.length == 0){
                            len = 0;
                        } else if(act_features.length > 0 && act_features.length < len){
                            len = act_features.length;
                        }
                        for(var i=0; i<len; i++){
                            act_features_str += act_features[i]+' ';
                        }
                    }
                    var label_class = '';
                    if(act_features_str.length > 0){
                        label_class = 'fa fa-tag';
                    }

                    var _pin = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [_data.lng, _data.lat]
                        },
                        properties: {
                            title: _data.title,
                            description: "<div class=''><div style='text-align: center'>"
                            +   "<div style='position: absolute;background-color: rgba(0,0,0,.6);color: white;padding: 10px;z-index:1'>"
                            +       "<span class='fa fa-"+_currency+"'></span>"
                            +       "<span style='margin-left: 2px;font-size: 22px'>"+ _price_str +"</span>"
                            +   "</div>"
                            +   "<span style='position:absolute;top: 20px;right:20px;font-size:20px;color:white;z-index: 1;'>"
                            +   "<a href='#' role='button' class='svg'><i title='想去' id='wanna-go-"+_data.id+"' class='fa fa-heart-o wanna_go gwk-font-white'"
                            +   " onclick=\"javascript: GwkMapController.activity.mark_wanna('"+_data.id+"')\"></i></a></span>"
                            +  "<a id='activity_link_image' href='"+_link+"' target='_blank'>"
                            +  "<div style='position: absolute;top: 8px;width: 240px;height: 50px;background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.3));'></div>"
                            + "    <img style='width:240px; height:150px' id='activity-image' src='"+GwkMapController.site_url+"/assets/images/common/none-image.png' alt=''></a></div>"
                            +      _special_html
                              // + "      <div class='' style='padding-left: 0px; padding-right: 15px;position: absolute;top: 120px;right: 0;'>"
                              // // + "        <div class='org-name-map'>"+_data.org_name+"</div>"
                              // + "        <div style='font-size: 8px; float: right; padding: 2px 0 3px 0; text-align: right;' class='col-md-12 col-sm-12 col-xs-12'>"
                              // + "          <span id='review-score-"+_data.id+"' class='map-star' style='line-height: 2.2; color:#ffb432'></span>"
                              // // + "            <a href='"+_link+"#main_review' target='_blank' style='background-color: rgba(33,150,243,0.7);color: #ffffff; border-radius: 3px; padding: 5px 6px 2px 1px;'>"
                              // // + "              <span id='review-count-"+_data.id+"' style='font-size: 12px;margin-left: 5px; margin-top: -3px'>-</span>"
                              // // + "              <span style='font-size: 12px;'> 评论</span>"
                              // // + "            </a>"
                              // + "        </div>"
                              // + "      </div>"
                            + "    <div class='caption' style='width: 240px; padding: 15px 8px 18px;background-color: white'>"
                            // + "      <div class='col-md-12 col-sm-12 col-xs-12' style='padding-left: 0px; padding-right: 0px;'>"
                            // + "          <div class='col-md-6  col-sm-6 col-xs-6 left'>"
                            // + "              <span>"+_period_str+"</span>"
                            // + "          </div>"
                            // + "          <div class='col-md-6 col-sm-6 col-xs-6 right' style='text-align: right'>"
                            // + "              <span class='fa fa-"+_currency+"'></span>"
                            // + "              <span>"+ _price_str +"</span>"
                            // + "          </div>"
                            // + "      </div>"
                            + "      <div class='col-md-12 col-sm-12 col-xs-12' style='padding: 0 0 1px 0;'>"
                            + "         <h4 style='font-size: 16px;margin:0px'><a id='activity_link_title' href='"+_link+"' target='_blank'>"+_data.title+"</a></h4>"
                            + "         <input type='hidden' id='activity_link_val' value='"+_link+"'>"
                            //+ "          <p class='activity_content' style='color: #9E9E9E;'>"+_data.profile+"</p>"
                            + "      </div>"
                            + "      <div style='font-size: 8px;padding:0' class='col-md-12 col-sm-12 col-xs-12'>"
                            + "          <span class='"+label_class+"' style='font-size:12px;margin-right:8px;color:#8F92A5'> "+ act_features_str +"</span>"
                            + "          <span id='review-score-"+_data.id+"' class='map-star' style='line-height: 2.2; color:#ffb432;font-size:6px'></span>"
                            + "            <a href='"+_link+"#main_review' target='_blank' style='padding: 5px 6px 2px 1px;color:#8F92A5!important'>"
                            + "              <span id='review-count-"+_data.id+"' style='font-size: 12px;margin-left: 5px; margin-top: -3px'>-</span>"
                            + "              <span style='font-size: 12px;'> 评论</span>"
                            + "            </a>"
                            + "      </div>"
                            + "      <div style='height:1px; margin-top:-1px;margin-bottom: -18px;clear: both;overflow:hidden;'></div>"
                            + "    </div>"
                            + "</div>",
                            'marker-id': _data.id,
                            'marker-color': '#f86767',
                            'activity_type': _data.type,
                            activity_id: _data.id,
                            plan_id: _data.plan_id,
                            activity_link: _link,
                            distance: _data.distance
                        }
                    };
                    return _pin;
                    break;
                case 'org' :
                    var _link =  "#";//GwkMapController.site_url + 'activity/detail/' + _poi.id;
                    var _logo =  GwkMapController.site_url + _data.logo;
                    var _title = '<a href="' + _link + '">' + (
                            _data.hasOwnProperty('name') ? _data.name : (
                                _data.hasOwnProperty('title') ? _data.title : '没有标题')) + '</a>';
                    var _description = '<div class="panel panel-default" style="margin:0;padding:1px;">' +
                        '<div align="center"><a href="'+ _link + '" >'+_title+'</a></div>' +
                        '<div class="panel-body"><div style="float:left; width:120px;margin: 1px;"><img class="org-infobox-image" src="' + _logo + '"/></div>' +
                        '<div style="float:left; margin:0;padding-top: 10px;"  align="left"><ul class="org-detail-info">' +
                        ' <li class="fa fa-building-o fa-lg"></li>' + (_data.address == null ? "":_data.address ) + '<br>' +
                        ' <li class="fa fa-globe fa-lg"></li>' + (_data.city == null ? "":_data.city ) + '<br>' +
                        ' <li class="fa fa-envelope-o   fa-lg"></li>' + (_data.zipcode == null ? "":_data.zipcode ) + '<br>' +
                        ' <li class="fa fa-phone fa-lg"></li>' + (_data.phone_number == null ? "":_data.phone_number ) + '<br></ul></div>' +
                        '</div>' +
                        '<div style="margin: 2px;padding: 1px;">'+ (_data.aboutus == null ? "":_data.aboutus ) +'</div>' +
                        '</div>' +
                        '</div>';

                    var _pin = {

                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [_data.lon, _data.lat]
                        },
                        properties: {
                            title: '',
                            description: _description,
                            'marker-id': _data.id,
                            'marker-color': '#f86767',
                            poi_type: 'org',
                            poi_id: _data.id,
                            link: _link
                        }
                    };
                    return _pin;
                    break;
                default:
            }
        },

        cluster: function(sport_type){
            var cluster_radius = 150;
            switch(sport_type) {
                case 'activity' :
                    cluster_radius = 20;
                    break;
                default:
                    cluster_radius = 100;
            }

            var cluster_group =  L.markerClusterGroup({
                maxClusterRadius: cluster_radius,
                iconCreateFunction: function (cluster) {
                    var count = cluster.getChildCount();
                    return L.divIcon({ html: '<div class="marker-cluster gwk-cluster-icon-'+sport_type+'"></div><div class="cluster-number">'+count+'</div>', className: '', iconSize: L.point(40, 40) });
                },
                spiderfyOnMaxZoom: true, showCoverageOnHover: true, zoomToBoundsOnClick: true
            });
            return cluster_group;
        }

    }, //end of pin

    activity: {
        //触发点击活动按钮
        triggerToggleActivity: function(){
            $('#type-activity').trigger('click');;
        },

        //加载活动数据
        toggleActivity: function(){
            try{

                var _node = $("#type-activity");
                var _state_node = $(_node).find('input.togglePoiState');
                var _activityCluster = GwkMapController.GWKActivityClusterCache['activity'];
                if ('1' == _state_node.val()) { //hide activity layer
                    if(_activityCluster != null && undefined != _activityCluster){
                        GwkMapController.GwkActivityClusterGroup.removeLayer(_activityCluster);
                    }
                    _state_node.val("0");
                    $(_node).css('background-color', '#ffffff');
                } else {
                    if(_activityCluster != null && undefined != _activityCluster){
                        if(!GwkMapController.GwkActivityClusterGroup.hasLayer(_activityCluster)){
                            GwkMapController.GwkActivityClusterGroup.addLayer(_activityCluster);
                        }
                    } else {
                        //从后台加载活动数据并显示在地图上
                        $('body').css('cursor', "wait");
                        $("#map-loading").removeClass("hide");
                        var _js_url = "/getActivities/";
                        $.ajax({
                            url: _js_url,
                            dataType: "json",
                            cache: true,
                            success: function (data) {
                                $("#map-loading").addClass("hide");
                                var count = +data.length;
                                console.log("Get "+count+" data of activities.");
                                $("#map-loading").addClass("hide");
                                GwkMapController.activity.renderActivitys(data, false);
                                console.log("load and render activities ok!!!!!!");
                            },
                            error: function(e){
                                $("#map-loading").addClass("hide");
                                console.log("can NOT load script : " + _js_url +", err_msg="+e);
                            }
                        });
                    }
                    _state_node.val("1");
                    $(_node).css('background-color', '#03A9F4');
                }

            } catch(e){
                console.log("加载活动数据出错:" + e);
            }
            $('body').css('cursor', "auto");
        },
        //在地图上显示活动坐标
        renderActivitys: function(_data, _is_search){
            if(GwkMapController.init_user_location == null){
                GwkMapController.init_user_location = L.latLng(GwkMapController.default_location[0],GwkMapController.default_location[1]);

            }
            if (_data) {
                console.log("rendering activity data...");
                var _activityLayer = L.mapbox.featureLayer();
                var _clusterGroup = GwkMapController.pin.cluster('activity');
                if(_is_search){
                    GwkMapController.GWKSearchCache['activity'] = _clusterGroup;

                } else {
                    GwkMapController.GWKActivityClusterCache['activity'] = _clusterGroup;
                }
                var _pins = [];

                for (var i = 0; i < _data.length; i++) {

                    var _tmp_activity = _data[i];
                    var _location = $.parseJSON(_tmp_activity.location);
                    var _lat = _location.lat;
                    var _lng = _location.lon;

                    //console.log("Activity " + _tmp_activity.id +", lat=" + _lat +", lng=" + _lng);
                    if(_lat == null || _lng == null
                        || _lat=='' || _lng == ''
                        || _lng=='None' || _lng == 'None'){
                        continue;
                    }
                    _tmp_activity['lat'] = _lat;
                    _tmp_activity['lng'] = _lng;

                    var _act_latlng = L.latLng(_lat,_lng);
                    var _distance = _act_latlng.distanceTo(GwkMapController.init_user_location);
                    _tmp_activity["distance"] = _distance;
                    _tmp_activity["sport_type"] = 'activity';
                    var _pin = GwkMapController.pin.poi(_tmp_activity);
                    _pins.push(_pin);

                }//end for
                try {
                    console.log("-------avialable activity Count = " + _pins.length);
                    _clusterGroup.on('mouseover', GwkMapController.activity.activityMarkersOnMouseOver);
                    _clusterGroup.on('click', GwkMapController.activity.activityMarkersOnClick);
                    _activityLayer.on('layeradd', GwkMapController.activity.onActivityAddToMap);
                    GwkMapController.GwkPOILayerCache['activity'] = _activityLayer;

                    //获取100公里内的活动坐标并缩放地图
                    var _closet_latLngs = [];
                    var _top_5_latLngs = [];
                    var i=0;
                    for(var j=0; j<_pins.length; j++){
                        var _coords = _pins[j].geometry.coordinates;
                        var _latLng = L.latLng(_coords[1],_coords[0]);
                        var _distance = GwkMapController.init_user_location.distanceTo(_latLng) /1000;
                        if(GwkMapController.init_user_location.distanceTo(_latLng) <=100){
                            _closet_latLngs.push(_latLng);
                        }
                        //if(i<5){
                        //    _top_5_latLngs.push(_latLng);
                        //    i++;
                        //}
                    }
                    //if(_closet_latLngs.length == 0){
                    //    _closet_latLngs = _top_5_latLngs;
                    //}
                    if(_closet_latLngs.length > 0){
                        GwkMapController.render.boundToLatLngs(_closet_latLngs);
                    } else {
                        GwkMapController.map.setView(GwkMapController.init_user_location,12);
                    }

                    _activityLayer.setGeoJSON(_pins);
                    _clusterGroup.addLayer(_activityLayer);
                    GwkMapController.GwkActivityClusterGroup.addLayer(_clusterGroup);

                } catch(e){ console.log(e)}
            }
        },

        //鼠标移到地图上的Activity点时的事件响应方法
        activityMarkersOnMouseOver: function(e){
            var marker = e.layer;
            var feature = marker.feature;
            var _id = feature.properties.activity_id;
            var _activity_link = feature.properties.activity_link;
            var _desc = feature.properties.description;
            var _sport_type = 'activity';
            var _lat_lng = feature.geometry.coordinates;
            var _popup = GwkMapController.pin.popup(_sport_type,_lat_lng[1], _lat_lng[0], _desc);
            _popup.setContent(_desc);
            $('.marker-title').remove();
            $('#activity_btn').attr('href',_activity_link);
            var window_width= $(window).width();
            var zoom = GwkMapController.map.getZoom();
            if(zoom<3 && window_width <= 736){
                zoom = 3;
                GwkMapController.map.setZoom(zoom);
            }

            marker.bindPopup(_popup);
            marker.openPopup();
            GwkMapController.activity.getActivityInfo(_id);
        },

        //点击活动图标的事件
        activityMarkersOnClick: function(e){
            var marker = e.layer;
            var feature = marker.feature;
            var _id = feature.properties.activity_id;
            var _activity_link = feature.properties.activity_link;
            var _desc = feature.properties.description;
            var _sport_type = 'activity';
            var _lat_lng = feature.geometry.coordinates;


            var window_width= $(window).width();
            //对于小屏幕设备(移动设备,点击时弹出气泡,而非进入目的地页面)
            if(window_width > 736){
                window.open(_activity_link);
            } else {
                var _popup = GwkMapController.pin.popup(_sport_type,_lat_lng[1], _lat_lng[0], _desc);
                _popup.setContent(_desc);

                //去掉多余生成的title
                $('.marker-title').remove();
                //重置链接(手机端生成弹出气泡时,链接会被过滤掉)
                $('#activity_btn').attr('href',_activity_link);
                marker.bindPopup(_popup);

                //异步加载review信息及图片
                GwkMapController.activity.getActivityInfo(_id);
                //小屏幕设备点击后, 下移地图以留出空间显示完整气泡
                var zoom = GwkMapController.map.getZoom();
                if(zoom<2){
                    zoom = 2;
                    GwkMapController.map.setZoom(zoom);
                }
                var center = GwkMapController.map.latLngToContainerPoint(marker.getLatLng());
                //console.log('container x='+ center.x + ', y='+center.y);
                center.y = center.y - 100;
                var center_latlng = GwkMapController.map.containerPointToLatLng(center);
                //var polyline = L.polyline([marker.getLatLng(),center_latlng]).addTo(GwkMapController.map);
                setTimeout(function () {
                    GwkMapController.map.panTo(center_latlng);
                }, 100);

                marker.togglePopup();
            }

        },

        //活动添加到地图的事件响应方法
        onActivityAddToMap: function(e){
            var marker = e.layer;
            var feature = marker.feature;
            var _sport_type = 'activity';
            var _icon = GwkMapController.pin.icon(_sport_type);
            marker.setIcon(_icon);
        },

        getActivityInfo: function(_activity_id,_refresh){
            var markTogo= function(_activity_id, is_togo){
                //判断"想去"的状态
                if (is_togo) {
                    $('#wanna-go-'+_activity_id).removeClass('fa-heart-o')
                        .addClass('fa-heart')
                        .removeClass('gwk-font-white')
                        .addClass('gwk-font-red');
                    $('#wanna-go-'+_activity_id).hover(function () {
                        $(this).removeClass('fa-heart-o');
                        $(this).addClass('fa-heart');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-red');
                    }, function () {
                        $(this).removeClass('fa-heart-o');
                        $(this).addClass('fa-heart');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-red');
                    });
                } else {
                    $('#wanna-go-'+_activity_id).removeClass('fa-heart')
                        .addClass('fa-heart-o')
                        .removeClass('gwk-font-red')
                        .addClass('gwk-font-white');
                    $('#wanna-go-'+_activity_id).hover(function () {
                        $(this).removeClass('fa-heart-o');
                        $(this).addClass('fa-heart');
                        $(this).removeClass('gwk-font-white');
                        $(this).addClass('gwk-font-red');
                    }, function () {
                        $(this).removeClass('fa-heart');
                        $(this).addClass('fa-heart-o');
                        $(this).removeClass('gwk-font-red');
                        $(this).addClass('gwk-font-white');
                    });
                }
            };
            //根据获取的活动数据,填充弹出框的内容
            var fillData = function(_data){
                //加载活动图片
                if(_data.image_info != null && undefined != _data.image_info
                    && _data.image_info.photo_name != null && undefined != _data.image_info.photo_name){
                    //获取图片名称
                    var _image_name = _data.image_info.photo_name;
                    //拆分文件名和后缀
                    var _arr = _image_name.split('.');
                    //获取缩略图路径
                    _image_name = _arr[0]+'-small.jpg';
                    var _image_path = '/assets/images/activity/'+_activity_id+"/"+_image_name;
                    $('#activity-image').attr('src',_image_path);
                }
                //想去
                if(_data.is_togo != null && undefined != _data.is_togo){
                    markTogo(_activity_id,_data.is_togo);
                }

                //review和评分
                if(_data.review_info != null && undefined != _data.review_info){
                    var review_count = _data.review_info.review_count;
                    var review_score = _data.review_info.review_score;
                    $('#review-score-'+_activity_id).raty({
                        starType: 'i',
                        half: false,
                        readOnly: true,
                        score: review_score
                    });
                    $('#review-count-'+_activity_id).html(review_count);
                }
                //重置弹出气泡的链接(解决手机上链接被清空的问题)
                var activity_link = $('#activity_link_val').val();
                $('#activity_link_image').attr('href',activity_link);
                $('#activity_link_title').attr('href',activity_link);

            };
            //先从缓存里找
            var _data_cache = GwkMapController.GWKPOIContentCache['activity_info_cache_'+_activity_id];
            if(!_refresh && _data_cache !=null && undefined != _data_cache) {
                fillData(_data_cache);
            } else {
                var _url = '/activity/ajax/activityInfo/'+_activity_id;
                $.ajax({
                    async: true,
                    url: _url,
                    dataType: "json",
                    cache: false,
                    success: function (_data) {
                        if(_data){
                            //进行缓存
                            GwkMapController.GWKPOIContentCache['activity_info_cache_'+_activity_id] = _data;
                            //填充数据
                            fillData(_data);
                        }
                    },
                    error: function (e) {
                        console.log("获取 id=" + _activity_id + "的信息失败!" + e);
                    }
                });
            }

        },

        mark_wanna: function(_activity_id){
            $.get(
                '/user/ws/' + $.base64.encode(JSON.stringify({
                    func  : 'activity_mark_togo',
                    id    : _activity_id,
                    sport_type : 'activity'
                })),
                function(_resp) {
                    if('success' == _resp.status) {
                        //success
                        GwkMapController.helper.showMsg(_resp.data,'info');
                    } else if('error' == _resp.status) {
                        //error
                        GwkMapController.helper.showMsg(_resp.message,'error');
                    }

                    //刷新视图
                    GwkMapController.activity.getActivityInfo(_activity_id,true);

                });
        }

    }, //end of activity

    /*
     * config start
     */
    ws_url: '//' + window.location.host + '/',
    site_url: '//' + window.location.host + '/',
    map: null,
    zoom: 10,
    default_location: [39.9500, -75.1667],

    default_map_type: 'mapbox.emerald',

    bing_map_key: 'Avzz40HI34eq77CY-xHxkno6ysgzB8_74fyxlmIncDcIPId4LlOMm0GqkglhFVLA',
    google_map_key: 'AIzaSyDh4CWAi20-O63nnRun2Q95x8ELxL28LJY',
    mapbox_api_key: 'pk.eyJ1Ijoicm9iaW55YW8iLCJhIjoiY2lnazI3MWxqMDBhOXQzbHV3ODlja3J0YSJ9.xrFAKxKy1c4ellPUgOVUNQ',

    init_user_location: null,
    init_user_lat: 0,
    init_user_lng: 0,
    default_cusor: null,
    enable_bound: true,

    GwkUserLayer: null,
    GwkSatelliteLayer: null,
    GwkBaseMapGroup : null,
    GwkStreetLayer: null,
    GwkPOIClusterGroup :  null,
    GwkUtilLayer : null,
    GwkActivityLayer : null,
    GwkActivityClusterGroup : null,
    GwkInfoboxLayer : null,

    geoLocationProvider: null,

    GWKPOIClusterCache: new Array(),
    GWKActivityClusterCache: new Array(),
    GwkPOILayerCache: new Array(),
    GWKSearchCache: new Array(),
    GWKMarkerCache: {},
    GWKPOIContentCache: new Array(),

    googleMap: null,
    placeService: null,

    closestPOICount: 5,

    userMarker: null,

    userAgent: navigator.userAgent,

    userCountryCode: null,

    userAddress: null,

    scriptLoaded: false,

    asset_path: '',

    mapInited: false,

    /*
     config ends
     */
}
