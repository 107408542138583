/**
 * Created by Robin on 16/6/16.
 */
var GwkPoiImageHelper ={

    img_path : 'assets/images/poi',

    default_img: 'assets/images/common/none-image.png',

    sport_types : null,

    google_api_loaded : false,

    placeService : null,

    cache_array : [],

    country_code: null,

    init: function(_assets_path, _init_google, _country_code){
        GwkPoiImageHelper.country_code = _country_code;
        if(_assets_path != null){
            GwkPoiImageHelper.img_path = _assets_path + GwkPoiImageHelper.img_path;
            GwkPoiImageHelper.default_img = _assets_path + GwkPoiImageHelper.default_img;
            console.log('img path = ' + GwkPoiImageHelper.img_path);
            console.log('default_img  = ' + GwkPoiImageHelper.default_img);
        }
        //加载所有运动类型的定义
        var _js_url = "/jss/"+ $.base64.encode('all_sport_types');
        $.ajax({
            url: _js_url,
            async: true,
            dataType: "json",
            cache:true,
            success: function(_data){
                GwkPoiImageHelper.sport_types = _data;
            },
            error: function(e){
                console.log("can NOT load script : " + _js_url +", err_msg="+e);
            }
        });

        if(_init_google){

            if(_country_code != null && _country_code =='CN'){
                console.log('Tianchao user can not access google ... no google API initialed');
                return;
                //_google_api_url = 'https://www.google.cn/maps/api/js?key=AIzaSyBnBZpOf1MouY-ar4k5mPd3GRgPXZnEQ8M&libraries=places';
            }
            var _google_api_url ='https://maps.googleapis.com/maps/api/js?key=AIzaSyBnBZpOf1MouY-ar4k5mPd3GRgPXZnEQ8M&libraries=places';
            console.log(_google_api_url);
            $.ajax({
                url: _google_api_url,
                async: true,
                dataType: "script",
                cache:true,
                success: function(_data){
                    try{
                        var googleMap = new google.maps.Map(document.getElementById('map-google'),{});
                        GwkPoiImageHelper.placeService = new google.maps.places.PlacesService(googleMap);
                        console.log("Google Map init ok.");
                        GwkPoiImageHelper.google_api_loaded = true;
                    } catch(e) {
                        console.log("Google Map init failed!");
                        console.log(e);
                    }
                },
                error: function(e){
                    console.log("can NOT load script : " + _google_api_url +", err_msg="+e);
                }
            });

        }

        return this;

    },

    getOneBigImg : function(sport_type, poi_id, lat, lng, poi_name, _callback){
        return GwkPoiImageHelper.getOneSpecifiedSizeImg(sport_type, poi_id, lat, lng, poi_name, 'big' ,_callback)
    },

    //获取一张POI的缩略图
    getOneThumbnail : function(sport_type, poi_id, lat, lng, poi_name, _callback){
        return GwkPoiImageHelper.getOneSpecifiedSizeImg(sport_type, poi_id, lat, lng, poi_name, 'small' ,_callback)
    },

    getOneMediumImg : function(sport_type, poi_id, lat, lng, poi_name, _callback){
        return GwkPoiImageHelper.getOneSpecifiedSizeImg(sport_type, poi_id, lat, lng, poi_name, 'medium' ,_callback)
    },

    getOneSpecifiedSizeImg : function(sport_type, poi_id, lat, lng, poi_name, size, _callback){
        try{
            //先尝试获取后台POI上传的图片
            var _query_server_url = '/poi/getphoto/poi/'+poi_id;
            //先从缓存里找找到就直接回调并返回
            var _img_json = GwkPoiImageHelper.cache_array[poi_id];
            if(_img_json != null){
                _callback(_img_json);
                return;
            }
            $.ajax({
                async: true,
                url: _query_server_url,
                dataType: "json",
                cache: true,
                success: function (_data) {
                    if(_data.length > 0){
                        var names=_data[0].photo_name.split('.');
                        _img_json = {
                            poi_id : poi_id,
                            img_url : GwkPoiImageHelper.img_path+'/'+poi_id+'/'+names[0]+'-'+(size == 'big' ? '' : size)+names[1],
                            img_source: 'wildkiz.com',
                            img_width: 240,
                            img_height:150,
                            user_name: _data[0].name,
                            user_id: _data[0].uid
                        };
                        //加回缓存
                        GwkPoiImageHelper.cache_array[poi_id] = _img_json;
                        _callback(_img_json);
                    } else {
                        //如果没有上传的图片,则取该POI对应的运动类型预置的随机图片显示
                        console.log('no uploaded img for poi ' + sport_type + ":" + poi_id);
                        GwkPoiImageHelper.getRandomImage(sport_type, poi_id, lat, lng, poi_name, _callback, size);
                    }

                },
                error: function (e) {
                    console.log("从后台获取 poi_id=" + poi_id + "的图片信息失败!" + e);
                    //获取目的地随机图片
                    GwkPoiImageHelper.getRandomImage(sport_type, poi_id, lat, lng, poi_name, _callback, size);
                }
            });
        } catch(e){
            console.log("加载POI图片失败" + e);
            GwkPoiImageHelper.getRandomImage(sport_type, poi_id, lat, lng, poi_name, _callback, size);
        }


    },

    //获取目的地随机图片信息
    getRandomImage : function(sport_type, poi_id, lat, lng, poi_name, _callback, _size, _multiple){

        try{
            //先查询运动类型对应的随机图片张数
            var _random_img_count = 0;
            if(GwkPoiImageHelper.sport_types != null){
                $.each(GwkPoiImageHelper.sport_types, function (i, tags) {
                    if (sport_type == tags.code) {
                        _random_img_count = tags.random_img;
                    }
                });
            }
            console.log(sport_type + " random img count=" + _random_img_count);
            if(_random_img_count > 0){
                //根据poi_id产生一个随机数
                Math.seed = parseInt(poi_id);
                Math.seededRandom = function(min, max) {
                    max = max || 1;
                    min = min || 0;
                    Math.seed = (Math.seed * 9301 + 49297) % 233280;
                    var rnd = Math.seed / 233280.0; return min + rnd * (max - min);
                };
                var _number = Math.seededRandom(1, _random_img_count);
                var _number = parseInt(_number);
                var _img_url = '';
                var _img_small_url = '';
                var _img_medium_url = '';
                var _img_width = 240;
                var _img_height = 150;
                if(_size == 'small'){
                    _img_url = GwkPoiImageHelper.img_path +'/'+sport_type+ '/random/small/' + _number + '-small.jpg';
                }else if(_size == 'medium'){
                    _img_url = GwkPoiImageHelper.img_path +'/'+sport_type+ '/random/medium/' + _number + '-medium.jpg';
                } else {
                    _img_url = GwkPoiImageHelper.img_path +'/'+sport_type+ '/random/' + _number + '.jpg';
                    _img_small_url = GwkPoiImageHelper.img_path +'/'+sport_type+ '/random/small/' + _number + '-small.jpg';;
                    _img_medium_url = GwkPoiImageHelper.img_path +'/'+sport_type+ '/random/medium/' + _number + '-medium.jpg';;
                    _img_width = null;
                    _img_height = null;
                }
                console.log("poi id="+poi_id + " random img url=" + _img_url);
                var _img_json = {
                    poi_id : poi_id,
                    img_url : _img_url,
                    //img_small_url: img_small_url,
                    //_img_medium_url: _img_medium_url,
                    img_source: 'random',
                    img_width: _img_width,
                    img_height:_img_height
                };
                if(_multiple){
                    var _img_json_arr = [_img_json];
                    console.log('Get 1 random img, and search more from google ...');
                    GwkPoiImageHelper.getImgsFromGoogle(sport_type, poi_id, lat, lng, poi_name, _callback, _img_json_arr, 9);
                } else {
                    _callback(_img_json);
                }


            } else {
                //如果没有随机图片, 则调用Google API获取
                if(GwkPoiImageHelper.google_api_loaded){
                    if(_multiple){
                        GwkPoiImageHelper.getImgsFromGoogle(sport_type, poi_id, lat, lng, poi_name, _callback,[],10);
                    } else {
                        GwkPoiImageHelper.getSingleImgFromGoogle(sport_type, poi_id, lat, lng, poi_name, _callback);
                    }
                } else {
                    GwkPoiImageHelper.noImageFound(_callback, poi_id);
                }
            }

        } catch(e){
            console.log("加载随机POI图片失败" + e);
            if(! _multiple){
                GwkPoiImageHelper.noImageFound(_callback, poi_id);
            }
        }
    },

    //从Google获取一张图片信息
    getSingleImgFromGoogle : function(sport_type, poi_id, lat, lng, poi_name, _callback){
        if(! GwkPoiImageHelper.google_api_loaded){
            console.log('Google API not ready yet...');
            GwkPoiImageHelper.noImageFound(_callback, poi_id);
            return;
        }

       var _img_json = GwkPoiImageHelper.cache_array[poi_id];
        if(_img_json != null){
            _callback(_img_json);
            return;
        }

        var _request = {
            location: {lat:lat*1, lng:lng*1},
            radius: '1000',
            query: poi_name + ", " + sport_type
        };
        try{
            GwkPoiImageHelper.placeService.textSearch(_request,
                function (results, status){
                    console.log("google place query result..." +  status);
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                        var place = results[0];
                        var photos = place.photos;
                        if(photos != null && photos != undefined && photos.length > 0){
                            var _photo = photos[0];
                            var _h = _photo.height;
                            var _w = _photo.width;
                            var _max_height = 240;
                            var _max_width = 240;
                            var _img_height =
                                parseInt( _w < _h ? _max_height : _h/(_w/_max_width));
                            var _url = _photo.getUrl({'maxWidth': _max_width, 'maxHeight': _max_height});
                            console.log('Google img url = ' + _url);
                            _img_json = {
                                poi_id : poi_id,
                                img_url : _url,
                                img_source: 'google',
                                img_width: 240,
                                img_height:_img_height
                            };
                            GwkPoiImageHelper.cache_array[poi_id] = _img_json;
                            _callback(_img_json);
                        } else {
                            console.log('no google image for this poi');
                            GwkPoiImageHelper.noImageFound(_callback, poi_id);
                        }
                    } else if(status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS){
                        console.log('no google image for this poi');
                        GwkPoiImageHelper.noImageFound(_callback, poi_id);
                    }
                }
            );
        } catch(e){
            console.log("从Google加载POI图片失败" + e);
            GwkPoiImageHelper.noImageFound(_callback, poi_id);
        }

    },

    //获取POI的多张大图以滚动显示
    getImgs: function(sport_type, poi_id, lat, lng, poi_name, _callback){
        try{
            //先尝试获取后台POI上传的图片
            var _query_server_url = '/poi/getphoto/poi/'+poi_id;
            var _img_json_arry = [];
            $.ajax({
                async: true,
                url: _query_server_url,
                dataType: "json",
                cache: true,
                success: function (_data) {
                    if(_data.length > 0){
                        for(var i=0; i<_data.length; i++){
                            var names=_data[i].photo_name.split('.');
                            var _img_json = {
                                poi_id : poi_id,
                                img_url : GwkPoiImageHelper.img_path+'/'+poi_id+'/'+names[0]+'.'+names[1],
                                //img_small_url : GwkPoiImageHelper.img_path+'/'+poi_id+'/'+names[0]+'-small.jpg',
                                img_source: 'wildkiz.com',
                                user_name: _data[i].name,
                                user_id: _data[i].uid
                            };
                            _img_json_arry.push(_img_json);
                        }
                        if(_data.length < 10){
                            var _count = 10 - _data.length;
                            GwkPoiImageHelper.getImgsFromGoogle(sport_type, poi_id, lat, lng, poi_name, _callback, _img_json_arry, _count);
                        } else {
                            _callback(_img_json_arry);
                        }
                    } else {
                        //如果没有上传的图片,则取该POI对应的运动类型预置的随机图片显示
                        console.log('no uploaded img for poi ' + sport_type + ":" + poi_id);
                        GwkPoiImageHelper.getRandomImage(sport_type, poi_id, lat, lng, poi_name, _callback, 'large', true);
                    }

                },
                error: function (e) {
                    console.log("从后台获取 poi_id=" + poi_id + "的图片信息失败!" + e);
                    //获取目的地随机图片
                    GwkPoiImageHelper.getRandomImage(sport_type, poi_id, lat, lng, poi_name, _callback, 'large', true);
                }
            });
        } catch(e){
            console.log("加载POI图片失败" + e);
            GwkPoiImageHelper.getRandomImage(sport_type, poi_id, lat, lng, poi_name, _callback, 'large', true);
        }

    },

    getImgsFromGoogle: function(sport_type, poi_id, lat, lng, poi_name, _callback, _previous_imgs, _count){

        if(GwkPoiImageHelper.country_code != null && GwkPoiImageHelper.country_code =='CN'){
            console.log('Tianchao user can not access google ... no google API initialed');
            _callback(_previous_imgs);
            return;
        }
        console.log("country_code=============" + GwkPoiImageHelper.country_code);
        var _google_api_url
            ='https://maps.googleapis.com/maps/api/js?key=AIzaSyBnBZpOf1MouY-ar4k5mPd3GRgPXZnEQ8M&libraries=places';

        $.ajax({
            url: _google_api_url,
            async: true,
            dataType: "script",
            cache:true,
            success: function(_data){
                try{
                    var googleMap = new google.maps.Map(document.getElementById('map-google'),{});
                    var _placeService = new google.maps.places.PlacesService(googleMap);
                    console.log("Google Map init ok 2.");
                    var _request = {
                        location: {lat:lat*1, lng:lng*1},
                        radius: '1000',
                        query: poi_name + ", " + sport_type
                    };
                    _placeService.textSearch(_request,
                        function (results, status) {
                            console.log("google place query result..." + status);
                            if (status == google.maps.places.PlacesServiceStatus.OK) {
                                var place = results[0];
                                var place_id = place.place_id;
                                _placeService.getDetails( { placeId: place_id},
                                    function(place2, status) {
                                        if (status == google.maps.places.PlacesServiceStatus.OK){
                                            var photos = place2.photos;
                                            if (photos != null && photos != undefined && photos.length > 0) {
                                                console.log("get " + photos.length + " photos ...");
                                                _count = _count > photos.length ? photos.length : _count;
                                                for (var i = 0; i < _count; i++) {
                                                    var _photo = photos[i];
                                                    var _thumb_url = _photo.getUrl({'maxWidth': 480, 'maxHeight': 250});
                                                    var _high_res_url = _photo.getUrl({'maxWidth': 1400, 'maxHeight': 900});
                                                    var _img_json = {
                                                        poi_id : poi_id,
                                                        img_url: _high_res_url,
                                                        img_small_url: _thumb_url,
                                                        img_source: 'google'
                                                    };
                                                    _previous_imgs.push(_img_json);
                                                }
                                            } else {
                                                console.log('no google image for this poi');
                                            }
                                        } else {
                                            console.log('Google API: query place details failed: ' + status);
                                        }
                                        _callback(_previous_imgs);
                                    }
                                );

                            } else if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                                console.log('no google image for this poi');
                                _callback(_previous_imgs);
                            }
                        }

                    );
                } catch(e) {
                    console.error("Failed to load imgs from google !");
                    console.log(e);
                    _callback(_previous_imgs);
                }

            },
            error: function(e){
                console.error("can NOT load script : " + _google_api_url +", err_msg="+e);
                _callback(_previous_imgs);
            }
        });

    },

    noImageFound : function(_callback, poi_id){
        var _img_json = {
            poi_id : poi_id,
            img_url : GwkPoiImageHelper.default_img,
            img_source: 'wildkiz.com',
            img_width: 240,
            img_height:150
        };
        _callback(_img_json);
    }
}